import axios from "axios"
import authHeader from "./auth.header";

class AuthService {
    login(user) {
        return axios({
            method: 'post',
            url: '/api/auth/login',
            headers: authHeader(),
            data: {
                user_name: user.username,
                password: user.password,
                authenticator_code: user.authCode,
                webcode: authHeader().webcode
            },
        }).then((response) => {
            return response.data
        })
    }

    loginAzure(idToken){
        return axios({
            method: 'post',
            url: '/api/auth/verifyAzureLogin',
            headers: authHeader(),
            data: {
                id_token: idToken,
                webcode: authHeader().webcode
            },
        }).then((response) => {
            return response.data
        })
    }

    getPortalFromWebcode(webcode){
        return axios({
            method: 'get',
            url: '/api/auth/get_portal_from_webcode',
            headers: authHeader(),
            params: {
                webcode: webcode
            }
        }).then((response) => {
            return response.data
        })
    }

    logout() {

    }

    async validatePin(params) {
        return axios({
            method: 'post',
            url: '/api/auth/validateTwoFaPin',
            headers: authHeader(),
            data: {
                authenticator_code: params.authenticatorCode,
                token: params.token
            },
        })
    }

    async generateTwoFaToken() {
        return axios({
            method: 'get',
            url: '/api/auth/generateTwoFaToken',
            headers: authHeader()
        }).then((response) => {
            return response.data
        })
    }

    async generateTwoFaTokenInvite(email) {
        return axios({
            method: 'get',
            url: '/api/auth/generateTwoFaTokenInvite',
            headers: authHeader(),
            params: {
                email
            }
        }).then((response) => {
            return response.data
        })
    }

    disableTwoFa(password) {
        return axios({
            method: 'delete',
            url: '/api/auth/disableTwoFa',
            headers: authHeader(),
            params: {
                password: password
            }
        })
    }

    refreshJwtToken(refresh_token) {
        return axios({
            method: 'get',
            url: '/api/auth/refreshJwtToken',
            headers: authHeader(),
            params: {
                refresh_token: refresh_token
            }
        }).then((response) => {
            return response.data
        })
    }
    
    searchApiLogins(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/auth/search_api_logins',
            headers: authHeader(),
            data: {
                name: query.name,
                role_id: query.role_id,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder,
            }
        })
    }

    registerApiLogin(name, roleId) {
        return axios({
            method: 'post',
            url: '/api/auth/register_api_login',
            headers: authHeader(),
            params: {
                name,
                roleId
            }
        })
    }

    deleteApiLogin(id) {
        return axios({
            method: 'post',
            url: '/api/auth/delete_api_login',
            headers: authHeader(),
            params: {
                id
            }
        })
    }

    getPortalConfiguration(){
        return axios({
            method: 'get',
            url: '/api/auth/get_portal_configuration',
            headers: authHeader()
        })  
    }

    updatePortalConfiguration(portalConfigurations){
        return axios({
            method: 'post',
            url: '/api/auth/update_portal_configuration',
            headers: authHeader(),
            data: {
                portal_id: portalConfigurations.portalId,
                allow_azure_sso: portalConfigurations.allowAzureSSO, 
                province_localization: portalConfigurations.provinceLocalization,
                postal_code_localization: portalConfigurations.postalCodeLocalization,
                transaction_days_back: portalConfigurations.transactionDaysBack,
                services_email: portalConfigurations.servicesEmail,
                receipt_generator: portalConfigurations.receiptGenerator,
                receipt_email: portalConfigurations.receiptEmail,
                coupon_length: portalConfigurations.couponLength,
                show_response_message: portalConfigurations.showResponseMessage, 
                show_gst_reg: portalConfigurations.showGSTReg
            }
        });
    }
}

export default new AuthService()