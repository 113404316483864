<template>
    <div data-app>
        <b-card no-body class="mt-3">
            <b-card-header>
                <font-awesome-icon
                    :icon="isCollapsed ? 'chevron-down' : 'chevron-up'"
                        class="nav-icon pointer mr-2"
                        @click="toggleCollapse"
                    :title="
                        isCollapsed
                            ? 'Expand Search Criteria'
                            : 'Collapse Search Criteria'
                    "
                    />
                Search Criteria
                <span v-if="isSavedSearch"> - {{ savedsearch.name }} </span>
                <span v-if="hasSearchFields">{{ getSavedSearchFields }}</span>
                <span class="align-middle">
                    <span
                        v-show="isCollapsed && isLoading"
                        class="spinner-border spinner-border-sm spinner"
                    />
                    <font-awesome-icon
                        icon="fa-rotate-right"
                        class="nav-icon action-btn pointer"
                        v-show="isCollapsed && !isLoading"
                        @click="reloadDynamicSearch"
                    />
                </span>
            </b-card-header>
            <b-card-body class="pt-0" :class="{ hide: isCollapsed }">
                <div class="table-container">
                    <div class="new-search pt-2" @click="onNewSearch">
                        <span class="d-md-none mr-1">Search Criteria</span>
                        <font-awesome-icon
                            icon="fa-plus-square"
                            class="nav-icon action-btn pointer"
                        />
                    </div>
                    <b-table
                        striped
                        :fields="criteriaFields"
                        :items="criteria"
                        :key="criteriaKey"
                        responsive="true"
                        stacked="sm"
                    >
                        <template #cell(actions)="row">
                            <div
                                class="trash-can"
                                :class="{
                                    'hide-trash-can':
                                        isMobile && row.item.required,
                                }"
                            >
                                <font-awesome-icon
                                    icon="fa-trash-can"
                                    class="nav-icon action-btn pointer"
                                    @click="deleteSearchCriteria(row.item)"
                                />
                            </div>
                        </template>
                        <template #cell(displayValue)="row">
                            <template v-if="row.item.type == 'Text'">
                                <b-form-input
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                    @keyup.enter="reloadDynamicSearch"
                                >
                                </b-form-input>
                            </template>
                            <template v-if="row.item.type == 'Number'">
                                <b-form-input
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                    @keyup.enter="reloadDynamicSearch"
                                    type="number"
                                    min="0"
                                >
                                </b-form-input>
                            </template>
                            <template
                                v-else-if="row.item.type == 'HardwareType'"
                            >
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="hwType in hardwareTypes"
                                        :value="hwType"
                                        :key="hwType.id"
                                    >
                                        {{ hwType.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Product'">
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="prod in products"
                                        :value="prod"
                                        :key="prod.id"
                                    >
                                        {{ prod.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Province'">
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="prov in provinces"
                                        :value="prov"
                                        :key="prov.id"
                                    >
                                        {{ prov.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Region'">
                                <v-autocomplete
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                    :items="regions"
                                    item-text="fullDescription"
                                    item-value="id"
                                    label="Ex: London"
                                    return-object
                                    class="autocomplete"
                                >
                                </v-autocomplete>
                            </template>
                            <template v-else-if="row.item.type == 'IssueType'">
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="issue in issues"
                                        :value="issue.value"
                                        :key="issue.value"
                                    >
                                        {{ issue.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="row.item.type == 'SeverityLevel'"
                            >
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option class="text-success" :value="0">
                                        None
                                    </option>
                                    <option class="text-info" :value="1">
                                        Low
                                    </option>
                                    <option class="text-warning" :value="2">
                                        Medium
                                    </option>
                                    <option class="text-danger" :value="3">
                                        High
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Boolean'">
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option :value="false">False</option>
                                    <option :value="true">True</option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Date'">
                                <b-input-group>
                                    <b-form-datepicker
                                        :id="row.item.key"
                                        v-model="row.item.value"
                                        right
                                        :disabled="row.item.isRelative"
                                    ></b-form-datepicker>
                                    <b-dropdown right class="custom-date-dropdown" variant="outline-secondary" :text='row.item.currentRangeType || `Custom Date`'>
                                        <b-dropdown-item @click="setDateRange('custom', row.item)">Custom Date</b-dropdown-item>
                                        <b-dropdown-item @click="setDateRange('days', row.item, 0)">Today</b-dropdown-item>
                                        <b-dropdown-item @click="setDateRange('days', row.item, 1)">Yesterday</b-dropdown-item>
                                        <b-dropdown-item @click="setDateRange('weeks', row.item, 1)">Last Week</b-dropdown-item>
                                        <b-dropdown-item @click="setDateRange('months', row.item, 1)">Last Month</b-dropdown-item>
                                        <b-dropdown-item @click="showCustomOffsetModal(row.item)">Custom Offset</b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group>
                            </template>
                            <template
                                v-else-if="row.item.type == 'TransactionStatus'"
                            >
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="status in transactionStatuses"
                                        :value="status.value"
                                        :key="status.id"
                                    >
                                        {{ status.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'CardNumber'">
                                <b-form-group
                                    id="cardnumber-input-group"
                                    label-for="card-number"
                                >
                                    <div
                                        class="form-control"
                                        style="max-width: 100%; max-height:38px"
                                    >
                                        <span
                                            id="cardMiddle"
                                            style="
                                                color: lightgray;
                                                user-select: none;
                                            "
                                        >
                                            XXXXX - XXXXXX
                                        </span>
                                        <span
                                            id="cardBack"
                                            contenteditable="true"
                                            @input="
                                                (event) =>
                                                    inputCardNumberBack(
                                                        event,
                                                        row.item
                                                    )
                                            "
                                            style="
                                                min-width: 4.5ch;
                                                display: inline-block;
                                            "
                                        ></span>
                                    </div>
                                </b-form-group>
                            </template>
                            <template
                                v-else-if="
                                    row.item.type == 'CodeActivityVendor'
                                "
                            >
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="vendor in codeActivityVendors"
                                        :value="vendor.id"
                                        :key="vendor.id"
                                    >
                                        {{ vendor.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="row.item.type == 'CodeActivityState'"
                            >
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="state in codeActivityStates"
                                        :value="state.value"
                                        :key="state.value"
                                    >
                                        {{ state.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="row.item.type == 'CodeActivityType'"
                            >
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="activityType in codeActivityTypes"
                                        :value="activityType.value"
                                        :key="activityType.value"
                                    >
                                        {{ activityType.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'RecordType'">
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option :value="0"></option>
                                    <option
                                        v-for="rt in recordTypeOptions"
                                        :value="rt"
                                        :key="rt.value"
                                    >
                                        {{ rt.description }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Phase'">
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="phase in phases"
                                        :value="+phase.name"
                                        :key="+phase.name"
                                    >
                                        {{ phase.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="row.item.type == 'FirmwareType'"
                            >
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="firmwareType in firmwareTypes"
                                        :value="firmwareType"
                                        :key="firmwareType.id"
                                    >
                                        {{ firmwareType.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'AuditType'">
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="auditType in auditTypes"
                                        :value="auditType"
                                        :key="auditType.id"
                                    >
                                        {{ auditType.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="row.item.type == 'AuditOperation'"
                            >
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="auditOperation in auditOperations"
                                        :value="auditOperation"
                                        :key="auditOperation.id"
                                    >
                                        {{ auditOperation.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Users'">
                                <v-autocomplete
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                    :items="users"
                                    item-text="username"
                                    item-value="id"
                                    label="Ex: username"
                                    return-object
                                    class="autocomplete"
                                >
                                    <template v-slot:item="data">
                                        <div :class="{'red' : data.item.deleted}">
                                            {{ data.item.username }}
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </template>
                            <template v-else-if="row.item.type == 'Emails'">
                                <v-autocomplete
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                    :items="emails"
                                    item-text="email"
                                    item-value="id"
                                    label="Ex: email"
                                    return-object
                                    class="autocomplete"
                                >
                                </v-autocomplete>
                            </template>
                            <template
                                v-else-if="row.item.type == 'EventEmails'"
                            >
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="eventEmail in eventEmails"
                                        :value="eventEmail"
                                        :key="eventEmail"
                                    >
                                        {{ eventEmail }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'BayCount'">
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="bay in bayCount"
                                        :value="bay.name"
                                        :key="bay.id"
                                    >
                                        {{ bay.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="row.item.type == 'CustomFieldType'"
                            >
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="customFieldType in customFieldTypes"
                                        :value="customFieldType"
                                        :key="customFieldType.id"
                                    >
                                        {{ customFieldType.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="
                                    row.item.type == 'CustomFieldDataType'
                                "
                            >
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="customFieldDataType in customFieldDataTypes"
                                        :value="customFieldDataType"
                                        :key="customFieldDataType.id"
                                    >
                                        {{ customFieldDataType.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'UserType'">
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="userType in userTypes"
                                        :value="userType"
                                        :key="userType.id"
                                    >
                                        {{ userType.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template
                                v-else-if="row.item.type == 'TransactionType'"
                            >
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="transactionType in transactionTypes"
                                        :value="transactionType"
                                        :key="transactionType.id"
                                    >
                                        {{ transactionType.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'RefundType'">
                                <b-form-select
                                    :id="row.item.key"
                                    v-model="row.item.value"
                                >
                                    <option
                                        v-for="refundType in refundTypes"
                                        :value="refundType"
                                        :key="refundType.id"
                                    >
                                        {{ refundType.name }}
                                    </option>
                                </b-form-select>
                            </template>
                            <template v-else-if="row.item.type == 'Svmid'">
                                <b-input-group prepend="S">
                                    <b-form-input
                                        :id="row.item.key"
                                        v-model="row.item.value"
                                        @keyup.enter="reloadDynamicSearch"
                                    >
                                    </b-form-input>
                                </b-input-group>
                            </template>
                            <template v-else-if="row.item.type == 'Roles'">
                                <b-form-select
                                    :id="row.item.key.toString()"
                                    v-model="row.item.value"
                                    :disabled="false"
                                >
                                    <option
                                        v-for="role in roles"
                                        :value="role"
                                        :key="role.id"
                                    >
                                        {{ role.description }}
                                    </option>
                                </b-form-select>
                            </template>
                        </template>
                    </b-table>
                </div>
                <div>
                    <button
                        class="mt-3 btn btn-secondary btn-margin-right"
                        @click="clearSaveSearch"
                    >
                        <span>Clear</span>
                    </button>
                    <button
                        class="mt-3 btn btn-secondary btn-margin-right"
                        @click="$bvModal.show('search-saves')"
                    >
                        <span>Load</span>
                    </button>
                    <button
                        class="mt-3 btn btn-secondary btn-margin-right"
                        @click="trySaveSearch"
                    >
                        <span>{{ saveButtonLabel }}</span>
                    </button>
                    <SpinnerButton
                        v-on:click="$emit('resetPagination')"
                        @click="reloadDynamicSearch"
                        class="float-right"
                        :isLoading="isLoading"
                        text="Search"
                    />
                </div>
            </b-card-body>
         </b-card>
        <b-modal id="search-criteria" ref="search-criteria" hide-footer>
            <template #modal-title> Search Criteria </template>
            <div class="d-block text-center">
                <b-table
                    striped
                    :fields="availableCriteriaFields"
                    :items="availableCriteria"
                    @row-clicked="addSearchCriteria"
                    responsive="true"
                    stacked="sm"
                >
                </b-table>
            </div>
            <b-button
                class="mt-3"
                block
                @click="$bvModal.hide('search-criteria')"
                >Close</b-button
            >
        </b-modal>
        <b-modal id="search-saves" ref="search-saves" hide-footer>
            <template #modal-title> Saved Searches </template>
            <div class="d-block text-center">
                <div class="mark-default">
                    <font-awesome-icon
                        icon="fa-home"
                        class="nav-icon mark-default-btn action-btn"
                    ></font-awesome-icon>
                </div>
                <b-table
                    striped
                    hover
                    :fields="savedSearchesFields"
                    :items="savedSearches"
                    @row-clicked="onLoadSearch"
                    responsive="true"
                    stacked="sm"
                >
                    <template #cell(actions1)="row">
                        <b-form-checkbox
                            v-model="row.item.default"
                            @change="onDefaultClick(row.item)"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(actions2)="row">
                        <font-awesome-icon
                            icon="fa-trash-can"
                            class="nav-icon action-btn pointer"
                            @click="deleteSavedSearchItem(row.item)"
                        />
                    </template>
                </b-table>
            </div>
        </b-modal>
        <b-modal id="search-new-save" ref="search-new-save" hide-footer>
            <template #modal-title> Save Search </template>
            <div class="d-block text-center">
                <b-form-input v-model="savedSearchNameTemp"> </b-form-input>
            </div>
            <div class="text-right">
                <b-button class="mt-3" variant="primary" @click="saveSearch">
                    Save
                </b-button>
            </div>
        </b-modal>
        <AlertDialog ref="dlg-alert" />
        <b-modal id="set-custom-offset" ref="set-custom-offset"  hide-footer>
            <template #modal-title> Custom Offset </template>
            <b-input-group>   
                <b-form-input v-model="customOffset.number" type="number"></b-form-input>
                <b-input-group-append>
                    <b-form-select class="custom-select-style" id="periodType" v-model="customOffset.unit" :options="periodOptions"/>
                </b-input-group-append>
            </b-input-group>
            <b-button class="mt-3 right" variant="primary" @click="applyCustomOffset">
                Save
            </b-button>
        </b-modal>
    </div>
</template>

<script>
import { SpinnerButton } from '.';
import { AlertDialog } from './dialogs';
import {
    HardwareTypeService,
    ProductsService,
    RegionsService,
    UserPreferencesService,
    IssuesService,
    TransactionsService,
    CodeActivityService,
    FileArchiveService,
    FirmwareService,
    TagService,
    AuditService,
    UsersService,
    CustomFieldsService,
    EventsService,
    RolesService,
} from '../services';
import { convertUTCtoLocalDate } from '../utils/utils';

export default {
    name: 'DynamicSearchComponent',
    components: {
        SpinnerButton,
        AlertDialog,
    },
    props: {
        preferencesName: {
            type: String,
            required: true,
        },
        allCriteria: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            dlgAlertTitle: '',
            dlgAlertMessage: '',
            defaultSearch: '',
            savedsearch: {},
            savedSearchNameTemp: "",
            hardwareTypes: [],
            products: [],
            provinces: [],
            regions: [],
            issues: [],
            codeActivityVendors: [],
            codeActivityTypes: [],
            codeActivityStates: [],
            recordTypeOptions: [],
            transactionStatuses: [],
            phases: [],
            firmwareTypes: [],
            auditTypes: [],
            auditOperations: [],
            users: [],
            emails: [],
            eventEmails: [],
            roles: [],
            bayCount: [
                {
                    id: 1,
                    name: 24,
                },
                {
                    id: 2,
                    name: 150,
                },
            ],
            customFieldTypes: [],
            customFieldDataTypes: [],
            userTypes: [],
            transactionTypes: [],
            refundTypes: [],
            criteriaKey: 1,
            availableCriteria: [],
            availableCriteriaFields: [
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'pointer',
                    sortable: false,
                },
            ],
            criteria: [],
            criteriaFields: [
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'displayValue',
                    label: 'Value',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: 'column trash-can-container',
                    thStyle: { width: '30px' },
                },
            ],
            savedSearches: [],
            savedSearchesFields: [
                {
                    key: 'actions1',
                    label: '',
                    thStyle: { width: '30px' },
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'pointer',
                    sortable: false,
                },
                {
                    key: 'actions2',
                    label: '',
                    thStyle: { width: '30px' },
                },
            ],
            security: this.$store.state.auth.security,
            isCollapsed: false,
            activeCustomOffsetItem: null,
            customOffset: {
                number: 1,
                unit: 'days'
            },
            periodOptions: [
                { value: 'days', text: 'Days Ago' },
                { value: 'weeks', text: 'Weeks Ago' },
                { value: 'months', text: 'Months Ago' },
                { value: 'years', text: 'Years Ago' },
            ]
        };
    },
    mounted() {
        this.refreshData();
        this.getSavedSearches(true);

        this.criteria = this.allCriteria.filter(
            (crit) => crit.initial === true || crit.required === true
        );
        this.availableCriteria = this.allCriteria.filter(
            (crit) => !crit.initial && !crit.required
        );
        
        this.sortCriteria()
    },
    computed: {
        saveButtonLabel() {
            if (this.savedsearch.name) {
                return 'Save [' + this.savedsearch.name + ']';
            }
            return 'Save';
        },
        isSavedSearch() {
            return this.savedsearch.name != undefined;
        },
        hasSearchFields() {
            return this.criteria.filter((crit) => crit.value).length > 0;
        },
        getSavedSearchFields() {
            var searchFields = this.criteria.filter((crit) => crit.value);
            var searchFieldNames = searchFields.map((crit) => crit.name);
            var searchFieldsFormatted = searchFieldNames.sort().join(', ');
            return ' (' + searchFieldsFormatted + ')';
    },
        isMobile() {
            return this.$vuetify.breakpoint.width <= 750;
        },
    },
    methods: {
        onNewSearch() {
            this.$refs['search-criteria'].show();
        },
        reloadDynamicSearch() {
            this.isLoading = true;
            var searchQuery = this.generateSearchQuery();
            this.$emit('reloadDynamicSearch', {
                query: searchQuery,
                done: () => {
                    this.isLoading = false;
                },
            });
        },
        onDefaultClick(item) {
            var newSearches = [...this.savedSearches];
            for (var i = 0; i < newSearches.length; ++i) {
                newSearches[i] = this.savedSearches[i];
                var ns = newSearches[i];
                var def = item.default && ns.name == item.name;
                if (def != ns.default) {
                    ns.default = def;

                    UserPreferencesService.put({
                        type: this.preferencesName,
                        name: ns.name,
                        default: ns.default,
                        value: ns.value,
                        id: ns.id,
                    });
                }
            }

            for (var j = 0; j < newSearches.length; ++j) {
                var nj = newSearches[j];
                if (nj.initialDefault == nj.default) continue;
                nj.initialDefault = nj.default;
                UserPreferencesService.put({
                    type: this.preferencesName,
                    name: nj.name,
                    default: nj.default,
                    value: nj.value,
                    id: ns.id,
                });
            }
            this.savedSearches = newSearches;
        },
        forceCriteriaUpdate() {
            this.criteriaKey += 1;
        },
        resetAvailableCriteria() {
            var critList = [];
            var availCritList = [];
            this.criteria = critList;
            for (var i = 0; i < this.allCriteria.length; ++i) {
                var crit = this.allCriteria[i];

                if (
                    crit.type == 'HardwareType' &&
                    !(this.security.kioskRead || this.security.firmwareRead)
                ) {
                    continue;
                }

                if (crit.type == 'Product' && !this.security.productRead) {
                    continue;
                }

                if (
                    (crit.type == 'Region' || crit.type == 'Province') &&
                    !this.security.regionsRead
                ) {
                    continue;
                }

                if (crit.type == 'IssueType' && !this.security.eventRead) {
                    continue;
                }

                if (
                    (crit.type == 'TransactionStatus' ||
                        crit.type == 'TransactionType') &&
                    !this.security.transactionRead
                ) {
                    continue;
                }

                if (
                    (crit.type == 'CodeActivityState' ||
                        crit.type == 'CodeActivityVendor' ||
                        crit.type == 'CodeActivityType') &&
                    !this.security.codeActivityRead
                ) {
                    continue;
                }

                if (
                    crit.type == 'RecordType' &&
                    !this.security.fileArchiveRead
                ) {
                    continue;
                }

                if (
                    (crit.type == 'AuditType' ||
                        crit.type == 'AuditOperation') &&
                    !this.security.auditRead
                ) {
                    continue;
                }

                if (
                    (crit.type == 'Users' || crit.type == 'UserType') &&
                    !this.security.userRead
                ) {
                    continue;
                }

                if (
                    (crit.type == 'CustomFieldType' ||
                        crit.type == 'CustomFieldDataType') &&
                    !this.security.customFieldsRead
                ) {
                    continue;
                }

                if (crit.type == 'EventEmails' && !this.security.eventRead) {
                    continue;
                }

                if (crit.type == 'RefundType' && !this.security.refundRead) {
                    continue;
                }

                if (crit.type == 'Roles' && !this.security.roleRead) {
                    continue;
                }
                
                if (crit.required) {
                    critList.splice(critList.length, 0, crit);
                } else {
                    availCritList.splice(availCritList.length, 0, crit);
                }
                if (crit.defaultValue) {
                    crit.value = crit.defaultValue;

                    if (crit.type == 'Date') {
                        this.setDateRange('custom', crit, 0);
                    }
                }
            }

            this.criteria = critList;
            this.availableCriteria = availCritList;
            this.sortCriteria()
            this.forceCriteriaUpdate();
        },
        refreshData() {
            this.resetAvailableCriteria();
            if (this.security.kioskRead || this.security.firmwareRead) {
                this.getHardwareTypes();
            }
            if (this.security.productRead) {
                this.getProducts();
            }
            if (this.security.regionsRead) {
                this.getRegions();
                this.getProvinces();
            }
            if (this.security.eventRead) {
                this.getIssues();
            }
            if (this.security.transactionRead) {
                this.getTransactionStatuses();
                this.getTransactionTypes();
            }
            if (this.security.codeActivityRead) {
                this.getCodeActivityStates();
                this.getCodeActivityVendors();
                this.getCodeActivityTypes();
            }
            if (this.security.fileArchiveRead) {
                this.getRecordTypes();
            }
            if (this.security.auditRead) {
                this.getAuditTypes();
                this.getAuditOperations();
            }
            if (this.security.userRead) {
                this.getUsers();
                this.getUserTypes();
            }
            if (this.security.customFieldsRead) {
                this.getCustomFieldTypes();
                this.getCustomFieldDataTypes();
            }
            if (this.security.eventRead) {
                this.getEventEmails();
            }
            if (this.security.refundRead) {
                this.getRefundTypes();
            }
            if (this.security.roleRead) {
                this.getRoles();
            }
            this.getPhases();
            this.getFirmwareTypes();
        },
        getHardwareTypes() {
            HardwareTypeService.getKioskTypes()
                .then((response) => {
                    this.hardwareTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'HardwareTypeService.getKioskTypes failed'
                    );
                });
        },
        getProducts() {
            ProductsService.get()
                .then((response) => {
                    this.products = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(error, 'ProductsService.get failed');
                });
        },
        getProvinces() {
            RegionsService.getProvinces()
                .then((response) => {
                    this.provinces = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'RegionsService.getProvinces failed'
                    );
                });
        },
        getRegions() {
            RegionsService.get()
                .then((response) => {
                    this.regions = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(error, 'RegionsService.get failed');
                });
        },
        getRecordTypes() {
            FileArchiveService.getRecordTypes()
                .then((response) => {
                    this.recordTypeOptions = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'FileArchiveService.getRecordTypes failed'
                    );
                });
        },
        getIssues() {
            IssuesService.get()
                .then((response) => {
                    this.issues = response.data.issues;
                })
                .catch((error) => {
                    this.showErrorToast(error, 'IssuesService.get failed');
                });
        },
        getTransactionStatuses() {
            TransactionsService.getTransactionStatuses()
                .then((response) => {
                    this.transactionStatuses = response.data.statuses;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'TransactionsService.getTransactionStatuses failed'
                    );
                });
        },
        getCodeActivityStates() {
            CodeActivityService.states()
                .then((response) => {
                    this.codeActivityStates = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'CodeActivityService.states failed'
                    );
                });
        },
        getCodeActivityTypes() {
            CodeActivityService.types()
                .then((response) => {
                    this.codeActivityTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'CodeActivityService.types failed'
                    );
                });
        },
        getCodeActivityVendors() {
            CodeActivityService.vendors()
                .then((response) => {
                    this.codeActivityVendors = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'CodeActivityService.vendors failed'
                    );
                });
        },
        getPhases() {
            TagService.listPhaseTags()
                .then((response) => {
                    this.phases = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'TagService.listPhaseTags failed'
                    );
                });
        },
        getFirmwareTypes() {
            FirmwareService.getFirmwareTypes()
                .then((response) => {
                    this.firmwareTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'FirmwareService.getFirmwareTypes failed'
                    );
                });
        },
        getAuditTypes() {
            AuditService.getTypes()
                .then((response) => {
                    this.auditTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(error, 'AuditService.getTypes failed');
                });
        },
        getAuditOperations() {
            AuditService.getOperations()
                .then((response) => {
                    this.auditOperations = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'AuditService.getOperations failed'
                    );
                });
        },
        getUsers() {
            UsersService.getUsers()
                .then((response) => {
                    this.users = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(error, 'AuditService.getUsers failed');
                });
        },
        getEmails() {
            UsersService.getEmails()
                .then((response) => {
                    this.emails = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(error, 'AuditService.getEmails failed');
                });
        },
        getEventEmails() {
            EventsService.getEmails()
                .then((response) => {
                    this.eventEmails = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'EventsServiceService.getEmails failed'
                    );
                });
        },
        getCustomFieldTypes() {
            CustomFieldsService.getTypes()
                .then((response) => {
                    this.customFieldTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'CustomFieldsService.getTypes failed'
                    );
                });
        },
        getCustomFieldDataTypes() {
            CustomFieldsService.getDataTypes()
                .then((response) => {
                    this.customFieldDataTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'CustomFieldsService.getDataType failed'
                    );
                });
        },
        getUserTypes() {
            UsersService.getUserTypes('r')
                .then((response) => {
                    this.userTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'UsersService.getUserTypes failed'
                    );
                });
        },
        getTransactionTypes() {
            TransactionsService.getTransactionTypes()
                .then((response) => {
                    this.transactionTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'TransactionsService.getTransactionTypes failed'
                    );
                });
        },
        getRefundTypes() {
            TransactionsService.getRefundTypes()
                .then((response) => {
                    this.refundTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'TransactionsService.getRefundTypes failed'
                    );
                });
        },
        getRoles() {
            RolesService.getRoles(this.security.technician)
                .then((response) => {
                    this.roles = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(error, 'RolesService.getRoles failed');
                });
        },
        getSavedSearches(reload) {
            UserPreferencesService.list(this.preferencesName)
                .then((response) => {
                    this.savedSearches = response.data;
                    for (var i = 0; i < this.savedSearches.length; ++i) {
                        var ss = this.savedSearches[i];
                        if (ss.default === undefined) {
                            ss.default = false;
                        }
                        ss.initialDefault = ss.default;
                        if (ss.default && reload) {
                            this.setSearch(ss);
                        }
                    }
                    if (reload) {
                        this.reloadDynamicSearch();
                    }
                    else {
                        var previousSearch = this.savedSearches.find(s => s.name == this.savedsearch.name)
                        
                        if (previousSearch) {
                            this.setSearch(previousSearch)
                        }
                    }

                    this.isCollapsed = this.isSavedSearch;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'UserPreferencesService.list failed'
                    );
                });
        },
        onLoadSearch(item, index, event) {
            const element = event.srcElement;
            let eventStr = JSON.stringify(element);
            if (eventStr != '{}') return;

            this.setSearch(item);
            this.$refs['search-saves'].hide();
        },
        setSearch(item) {
            var missingPermissions = false;
            var savedCriteria = JSON.parse(item.value);
            this.resetAvailableCriteria();
            for (var i = 0; i < savedCriteria.length; ++i) {
                let saveItem = savedCriteria[i];
                var crit = this.addSearchCriteria(saveItem);
                if (crit != null) {
                    if (saveItem.value) {
                        crit.value = saveItem.value;
                    } 
                    else if (saveItem.calculatedValue){
                        if (crit.type == 'Date'){
                            this.setDateRange(saveItem.calculatedValue.type, crit, saveItem.calculatedValue.offset)
                        }
                    }
                    else {
                        if (crit.defaultValue) {
                            crit.value = crit.defaultValue;
                        }
                    }
                } else {
                    missingPermissions = true;
                }
                }

            if (missingPermissions) {
                this.$root.$bvToast.toast(
                    'User role is missing permissions to display all saved search fields',
                    {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
            }
                );
            }

            this.savedsearch = item;
        },
        clearSaveSearch() {
            this.savedsearch = {};
            this.resetAvailableCriteria();
        },
        trySaveSearch() {
            if (this.criteria.length == 0) {
                this.$refs['dlg-alert'].show(
                    'Error',
                    'You must have at least one search critera defined'
                );
                return;
            }
            this.savedSearchNameTemp = this.savedsearch.name

            this.$refs['search-new-save'].show();
        },
        saveSearch() {
            if (!this.savedSearchNameTemp) {
                this.$refs['dlg-alert'].show(
                    'Error',
                    'You must provide a name for the search criteria'
                );
                return;
            }

            this.savedsearch.name = this.savedSearchNameTemp

            var savedCriteria = [];
            for (var i = 0; i < this.criteria.length; ++i) {
                let crit = this.criteria[i];
                var newCrit = {};
                newCrit.key = crit.key;
                
                if (crit.calculatedValue){
                    newCrit.calculatedValue = crit.calculatedValue;
                }
                else {
                    newCrit.value = crit.value;
                }

                savedCriteria.splice(savedCriteria.length, 0, newCrit);
            }
            var value = JSON.stringify(savedCriteria);

            UserPreferencesService.put({
                type: this.preferencesName,
                name: this.savedsearch.name,
                default: this.savedsearch.default,
                value: value,
            }).then(() => {
                this.getSavedSearches();
            });

            this.$refs['search-new-save'].hide();
        },
        getCriteria(key) {
            for (var i = 0; i < this.allCriteria.length; ++i) {
                let crit = this.allCriteria[i];
                if (crit.key == key) {
                    return crit;
                }
            }
            return null;
        },
        generateSearchQuery() {
            var searchQuery = {};
            for (var j = 0; j < this.criteria.length; ++j) {
                let crit = this.criteria[j];
                if (crit.value === null || crit.value === undefined) continue;
                let availCrit = this.getCriteria(crit.key);
                if (availCrit.type == 'CardNumber') {
                    searchQuery[availCrit.searchQueryName + availCrit.backDescriptor] = crit.value.back;
                } else if (availCrit.searchQueryValueProperty) {
                    searchQuery[availCrit.searchQueryName] =
                        crit.value[availCrit.searchQueryValueProperty];
                } else {
                    searchQuery[availCrit.searchQueryName] = crit.value;
                }
            }
            return searchQuery;
        },
        deleteSearchCriteria(item) {
            for (var i = 0; i < this.criteria.length; ++i) {
                let crit = this.criteria[i];
                if (crit.key == item.key) {
                    this.criteria.splice(i, 1);
                        this.availableCriteria.splice(
                            this.availableCriteria.length,
                            0,
                            crit
                        );
                        this.sortCriteria()
                        this.forceCriteriaUpdate();

                    return;
                }
            }
        },
        addSearchCriteria(item) {
            for (var i = 0; i < this.availableCriteria.length; ++i) {
                let crit = this.availableCriteria[i];
                if (crit.key == item.key) {
                    this.availableCriteria.splice(i, 1);
                    this.criteria.splice(this.criteria.length, 0, crit);
                    this.sortCriteria()
                    this.forceCriteriaUpdate();
                    return crit;
                }
            }
            for (var j = 0; j < this.criteria.length; ++j) {
                let crit = this.criteria[j];
                if (crit.key == item.key) {
                    return crit;
                }
            }
            return null;
        },
        deleteSavedSearchItem(item) {
            UserPreferencesService.delete({
                type: this.preferencesName,
                id: item.id,
            }).then(() => {

                if (item.id == this.savedsearch.id) {
                    this.savedsearch = {}
                }

                this.getSavedSearches(true);
            });
        },
        inputCardNumberBack(ev, item) {
            if (!item.value) item.value = new Object();

            var text = ev.currentTarget.textContent;
            if (text.search(/(\r\n|\n|\r)/gm) > 0) {
                text = text.replace(/(\r\n|\n|\r)/gm, '');
            }

            if (String(text).length > 4) text = text.substring(0, 4);

            item.value.back = text;
            ev.currentTarget.textContent = text;
        },
        showErrorToast(error, message) {
            this.$root.$bvToast.toast(message, {
                title: 'Error',
                variant: 'danger',
                solid: true,
            });
        },
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed
        },
        sortCriteria() {
            this.availableCriteria = this.availableCriteria.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
            //this.criteria = this.criteria.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
        },
        setDateRange(rangeType, item, offset = 0) {
            let rangeLabel = '';
            //debugger; // eslint-disable-line
            switch(rangeType) {
                case 'days':
                    if(offset == 1) {
                        rangeLabel = 'Yesterday';
                    } 
                    else if (offset == 0){
                        rangeLabel = 'Today';
                    }
                    else {
                        rangeLabel = `${offset} Days Ago`;
                    }
                    break;
                case 'weeks':
                    if (offset == 0){
                        rangeLabel = 'This Week';
                    }
                    else if(offset == 1) {
                        rangeLabel = 'Last Week';
                    } else {
                        rangeLabel = `${offset} Weeks Ago`;
                    }
                    break;
                case 'months':
                    if (offset == 0){
                        rangeLabel = 'This Month';
                    }
                    else if(offset == 1) {
                        rangeLabel = 'Last Month';
                    } else {
                        rangeLabel = `${offset} Months Ago`;
                    }
                    break;
                case 'years':
                    if (offset == 0){
                        rangeLabel = 'This Year';
                    } 
                    else if(offset == 1){
                        rangeLabel = 'Last Year';
                    } else {
                        rangeLabel = `${offset} Years Ago`;
                    }
                    break;
                case 'custom':
                    rangeLabel = 'Custom Date';
                    break;
            }

            item.currentRangeType = rangeLabel;

            if (rangeType === 'custom') {
                item.isRelative = false;
                item.calculatedValue = null;
                this.$set(this.criteria, this.criteria.indexOf(item), item);
                return;
            }

            item.isRelative = true;
            let newDate = new Date();
            
            switch (rangeType) {
                case 'days':
                    newDate.setDate(newDate.getDate() - offset);
                    break;
                case 'weeks':
                    newDate.setDate(newDate.getDate() - (newDate.getDay() || 7) + 1); 
                    newDate.setDate(newDate.getDate() - (offset * 7)); 
                    if (item.subtype === 'end-date') {
                        newDate.setDate(newDate.getDate() + 6);
                    }
                    break;
                case 'months':
                    newDate.setMonth(newDate.getMonth() - offset);
                    if (item.subtype === 'start-date') {
                        newDate.setDate(1);
                    }
                    if (item.subtype === 'end-date') {
                        newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
                    }
                    break;
                case 'years':
                    if (item.subtype === 'start-date') {
                        newDate.setFullYear(newDate.getFullYear() - offset, 0, 1);
                    }
                    if (item.subtype === 'end-date') {
                        newDate.setFullYear(newDate.getFullYear() - offset + 1, 0, 0);
                    }
                    break;
            }

            item.value = convertUTCtoLocalDate(newDate).toISOString().slice(0, 10);
            item.calculatedValue = { type: rangeType, offset: offset, subtype: item.subtype };
            this.$set(this.criteria, this.criteria.indexOf(item), item);
        },
        showCustomOffsetModal(item){
            this.activeCustomOffsetItem = item;
            this.$bvModal.show('set-custom-offset')
        },
        applyCustomOffset(){
            if (this.activeCustomOffsetItem){
                this.setDateRange(this.customOffset.unit, this.activeCustomOffsetItem, this.customOffset.number);
                this.activeCustomOffsetItem = null;
                this.$bvModal.hide('set-custom-offset')
            }
        }
    }
};
</script>

<style scoped>
.new-search {
    text-align: right;
    margin-right: 12px;
    position: relative;
    top: 38px;
}
.mark-default {
    text-align: left;
    margin-left: 12px;
    position: relative;
    top: 38px;
}
.search-button-row {
    height: 80px;
}
.btn-margin-right {
    margin-right: 12px;
}

.hide {
    display: none;
}

::v-deep .column {
    vertical-align: middle;
}

.align-middle {
    vertical-align: middle;
    float: right;
}

.autocomplete {
    z-index: 99;
}

.red {
    color: red;
}

::v-deep .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@media (max-width: 775.5px) {
    .new-search {
        top: 0px;
        margin-bottom: 1rem;
    }

    .trash-can {
        display: flex;
        justify-content: flex-end;
        width: 86%;
    }

    ::v-deep .trash-can-container:has(.hide-trash-can) {
        display: none !important;
    }

    .custom-date-dropdown {
        flex: 0 0 100%; 
        max-width: 100%; 
    }
}

@media (max-width: 575.98px){
    .table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
        max-width: 80% !important;
        min-width: 60% !important;
        width: unset !important;
    }

    .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
        max-width: 40% !important;
        min-width: 20% !important;
        width: unset !important; 
    }
}


.custom-select-style {
  border-top-left-radius: 0; 
  border-bottom-left-radius: 0; 
  border-left: 1px solid #ced4da; 
}

.input-group .custom-select-style:focus {
  z-index: 1;
}

</style>