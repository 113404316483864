import axios from "axios"
import authHeader from "./auth.header";

class ImagesService {
    UploadImage(formData, image_type, product_id){
        var headers = {}

        Object.assign(headers, authHeader())
        Object.assign(headers, { "Content-Type": "multipart/form-data" })
        return axios({
            method: 'post',
            url: '/api/images/upload_image',
            headers: headers,
            data: formData,
            params: {
                image_type,
                product_id
            }
        })
    }
    getImageTypes() {
        return axios({
            method: 'get',
            url: '/api/images/get_image_types',
            headers: authHeader()
        })
    }
}

export default new ImagesService()