<template>
    <b-form>
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group
                    id="vmid-general-group"
                    label="VMID"
                    label-for="vmid-general-input"
                >
                    <b-form-input
                        id="vmid-general-input"
                        v-model="this.general.vmid"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="store-general-group"
                    label="Store"
                    label-for="store-general-input"
                >
                    <b-form-input
                        id="store-general-input"
                        v-model="this.general.store_description"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="region-general-group"
                    label="Region"
                    label-for="region-general-input"
                >
                    <b-form-input
                        id="region-general-input"
                        v-model="this.general.region_description"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="checkin-general-group"
                    :label="lastCheckInElapsed"
                    label-for="checkin-general-input"
                >
                    <b-form-input
                        id="checkin-general-input"
                        v-model="this.general.lastCheckInDt"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
    </b-form>
</template>

<script>
import KioskService from '../../services/kiosk.service';
import { getElapsedReadableTime, isDateTimeMin } from '../../utils/utils';

export default {
    name: 'General',
    props: {
        kioskId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            general: {},
            lastCheckInElapsed: 'calculating...',
            getGeneralIntervalId: null,
            hasNewCheckinIntervalId: null,
            checkinLabelTimerId: null,
        };
    },
    mounted() {
        this.getGeneral();
        this.getGeneralIntervalId = setInterval(this.getGeneral, 10000);
        this.hasNewCheckinIntervalId = setInterval(this.hasNewCheckin, 10000);
    },
    beforeDestroy() {
        // Clear the intervals to prevent them from running after the component is destroyed
        if (this.getGeneralIntervalId) {
            clearInterval(this.getGeneralIntervalId);
        }
        if (this.hasNewCheckinIntervalId) {
            clearInterval(this.hasNewCheckinIntervalId);
        }
        if (this.checkinLabelTimerId) {
            clearInterval(this.checkinLabelTimerId);
        }
    },
    methods: {
        getElapsedReadableTime,
        isDateTimeMin,
        updateCheckinLabel() {
            if (isDateTimeMin(this.general.last_checkin)) {
                this.lastCheckInElapsed = 'Last Check In: Never';
                return;
            }
            var lastCheckinTimestamp = new Date(this.general.last_checkin);
            var currentTimestamp = new Date();
            var diff = currentTimestamp - lastCheckinTimestamp;
            this.lastCheckInElapsed =
                'Last Check In: ' + this.getElapsedReadableTime(diff);
        },
        getGeneral() {
            this.loading = true;
            if (this.general){
                KioskService.general(this.kioskId).then((response) => {
                    if (response) {
                        this.general = response.data;
                        if (!isDateTimeMin(this.general.last_checkin)) {
                            this.general.lastCheckInDt = new Date(
                                Date.parse(this.general.last_checkin)
                            ).toLocaleString();
                        } else {
                            this.general.lastCheckInDt = 'N/A';
                        }
                        this.updateCheckinLabel();
                        if (this.checkinLabelTimerId) {
                            clearInterval(this.checkinLabelTimerId);
                        }
                        this.checkinLabelTimerId = setInterval(this.updateCheckinLabel, 1000);
                        this.loading = false;
                        document.title = 'Kiosk: ' + response.data.vmid;
                    }
                });
            }
        },
        hasNewCheckin() {
            if (this.general) {
                KioskService.hasNewCheckin(
                    this.general.id,
                    this.general.last_checkin
                ).then((response) => {
                    if (response) {
                        if (response.data) {
                            this.$emit('notifyRefresh');
                        }
                    }
                });
            }
        },
    },
};
</script>

<style scoped>
</style>
