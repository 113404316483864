import axios from "axios";
import authHeader from "./auth.header";

class UsersService {
    searchQuery(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/users/list',
            headers: authHeader(),
            data: {
                login: query.login,
                name: query.name,
                userType: query.userType,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    register(username, name, email, password, role, accessLevel) {
        return axios({
            method: 'post',
            url: '/api/users/register',
            headers: authHeader(),
            data: {
                id: 0,
                username: username,
                name: name,
                email: email,
                password: password,
                role: role,
                access_level: accessLevel
            }
        })
    }

    update(id, username, name, email, role, accessLevel) {
        return axios({
            method: 'post',
            url: '/api/users/update',
            headers: authHeader(),
            data: {
                id: id,
                username: username,
                name: name,
                email: email,
                role: role,
                access_level: accessLevel
            }
        })
    }

    updatePassword(id, username, password) {
        return axios({
            method: 'post',
            url: '/api/users/update_password',
            headers: authHeader(),
            data: {
                id: id,
                username: username,
                password: password
            }
        })
    }

    deleteUser(id) {
        return axios({
            method: 'delete',
            url: '/api/users/delete',
            headers: authHeader(),
            params: { userId: id }
        });
    }

    disableAccessCode(id) {
        return axios({
            method: 'post',
            url: '/api/users/disable_access_code',
            headers: authHeader(),
            params: { userId: id }
        });
    }

    getUser() {
        return axios({
            method: 'get',
            url: '/api/users/get_user',
            headers: authHeader()
        })
    }

    getUsers() {
        return axios({
            method: 'get',
            url: '/api/users/get_users',
            headers: authHeader()
        })
    }

    inviteUser(email, username, name, role, azure_account) {
        return axios({
            method: 'post',
            url: '/api/users/invite_user',
            headers: authHeader(),
            data: {
                email,
                username,
                name,
                role,
                azure_account
            }
        })
    }

    inviteRegister(inviteTokenId, password, authToken, authCode) {
        return axios({
            method: 'post',
            url: '/api/users/invite_register',
            headers: authHeader(),
            data: {
                invite_token_id: inviteTokenId,
                password,
                auth_token: authToken,
                auth_code: authCode,
                webcode: authHeader().webcode
            }
        })
    }

    inviteRegisterAzure(inviteTokenId, idToken) {
        return axios({
            method: 'post',
            url: '/api/users/invite_register_azure',
            headers: authHeader(),
            data: {
                invite_token_id: inviteTokenId,
                id_token: idToken,
                webcode: authHeader().webcode
            }
        })
    }

    convertUserAzure(userId, idToken) {
        return axios({
            method: 'post',
            url: '/api/users/convert_user_azure',
            headers: authHeader(),
            data: {
                user_id: userId,
                id_token: idToken
            }
        })
    }

    getUserTypes(request_type = null) {
        return axios({
            method: 'get',
            url: '/api/users/get_user_types',
            headers: authHeader(),
            params: { request_type }
        })
    }

    getEmails() {
        return axios({
            method: 'get',
            url: '/api/users/get_emails',
            headers: authHeader(),
        })
    }

    getUserAccess(userId) {
        return axios({
            method: 'get',
            url: '/api/users/get_user_access',
            headers: authHeader(),
            params: { userId: userId }
        })
    }

    updateUserAccess(userId, regionAccess, storeAccess) {
        return axios({
            method: 'post',
            url: '/api/users/update_user_access',
            headers: authHeader(),
            data: {
                user_id: userId,
                region_access: regionAccess,
                store_access: storeAccess
            }
        })
    }

    getAPIUserAccess(userId) {
        return axios({
            method: 'get',
            url: '/api/users/get_api_user_access',
            headers: authHeader(),
            params: { userId: userId }
        })
    }

    updateAPIUserAccess(userId, regionAccess, storeAccess) {
        return axios({
            method: 'post',
            url: '/api/users/update_api_user_access',
            headers: authHeader(),
            data: {
                user_id: userId,
                region_access: regionAccess,
                store_access: storeAccess
            }
        })
    }


    downloadCsv(query) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/users/download_csv',
            headers: authHeader(),
            params: {
                login: query.login,
                name: query.name,
                userType: query.userType,
            }
        })
    }

    downloadXlsx(query) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/users/download_xlsx',
            headers: authHeader(),
            params: {
                login: query.login,
                name: query.name,
                userType: query.userType,
            }
        })
    }

    addNewCsv(form_data) {
        var headers = {}

        Object.assign(headers, authHeader())
        Object.assign(headers, { "content-type": undefined })

        return axios({
            method: 'post',
            url: '/api/users/bulk_new',
            data: form_data,
            headers: headers
        })
    }
}
export default new UsersService();