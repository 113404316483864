<template>
    <div class="transactions-search">
        <b-form @submit.prevent="reloadTransactions">
            <div class="row row-cols-1 row-cols-md-4 g-4">
                <div class="col">
                    <b-form-group
                        id="date-start-group"
                        label="Date Start"
                        label-for="date-start"
                    >
                        <b-input-group>
                            <b-form-input
                                id="date-end-input"
                                v-model="searchQuery.dateStart"
                                placeholder="YYYY-MM-DD"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }"
                                autocomplete="off"
                            >
                            </b-form-input>
                            <b-input-group-append>
                                <b-form-datepicker
                                    id="date-start"
                                    v-model="searchQuery.dateStart"
                                    button-only
                                    right
                                ></b-form-datepicker>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="date-end-group"
                        label="Date End"
                        label-for="date-end"
                    >
                        <b-input-group>
                            <b-form-input
                                id="date-end-input"
                                v-model="searchQuery.dateEnd"
                                placeholder="YYYY-MM-DD"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }"
                                autocomplete="off"
                            >
                            </b-form-input>
                            <b-input-group-append>
                                <b-form-datepicker
                                    id="date-end"
                                    v-model="searchQuery.dateEnd"
                                    button-only
                                    right
                                ></b-form-datepicker>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-5 g-5">
                <div class="col">
                    <b-form-group
                        id="transactionid-input-group"
                        label="Transaction ID"
                        label-for="transaction-id"
                    >
                        <b-form-input
                            id="transaction-id"
                            v-model.number="searchQuery.transactionId"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="vmid-input-group"
                        label="VMID"
                        label-for="vmid"
                    >
                        <b-form-input
                            id="vmid"
                            v-model="searchQuery.vmid"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="terminalid-input-group"
                        label="Terminal ID"
                        label-for="terminal-id"
                    >
                        <b-form-input
                            id="terminal-id"
                            v-model="searchQuery.terminalId"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="merchantid-input-group"
                        label="Merchant ID"
                        label-for="merchant-id"
                    >
                        <b-form-input
                            id="merchant-id"
                            v-model="searchQuery.merchantId"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-5 g-5">
                <div class="col">
                    <TransactionStatusComponent
                        v-model="searchQuery.transactionStatusId"
                    />
                </div>
                <div class="col">
                    <RegionsComponent v-model="searchQuery.region" />
                </div>
                <div class="col">
                    <ProductsComponent v-model="searchQuery.productId" />
                </div>
                <div class="col">
                    <HardwareTypeComponent
                        v-model="searchQuery.hardwareTypeId"
                    />
                </div>
                <div class="col">
                    <IssueSelectComponent
                        noSelectionDescriptor="All"
                        v-model="searchQuery.issueTypeId"
                    />
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-5 g-5">
                <div class="col">
                    <b-form-group
                        id="cardnumber-input-group"
                        label="Card Number"
                        label-for="card-number"
                    >
                        <div class="form-control">
                            <span
                                id="cardMiddle"
                                style="color: lightgray; user-select: none"
                            >
                                XXXXXXX-XXXXXX
                            </span>
                            <span
                                id="cardBack"
                                contenteditable="true"
                                @input="inputCardNumberBack"
                                style="min-width: 4ch; display: inline-block"
                            ></span>
                        </div>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="exclude-not-in-output-group">
                        <label class="spacing-label"></label>
                        <b-form-checkbox
                            switch
                            class="aligned-checkbox"
                            v-model="searchQuery.excludeNotInOutput"
                            >Exclude Not In Output</b-form-checkbox
                        >
                    </b-form-group>
                </div>
            </div>
            <div class="row align-items-end">
                <div class="col">
                    <SpinnerButton
                        @click="reloadTransactions"
                        :isLoading="isLoading"
                        text="Search"
                        class="float-right"
                    />
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
import {
    TransactionStatusComponent,
    HardwareTypeComponent,
    ProductsComponent,
    IssueSelectComponent,
    RegionsComponent,
    SpinnerButton,
} from '../../components';

export default {
    name: 'TransactionsSearch',
    components: {
        TransactionStatusComponent,
        RegionsComponent,
        ProductsComponent,
        IssueSelectComponent,
        HardwareTypeComponent,
        SpinnerButton,
    },
    data() {
        return {
            searchQuery: {
                dateStart: new Date().toISOString().slice(0, 10),
                dateEnd: new Date().toISOString().slice(0, 10),
                transactionId: '',
                vmid: '',
                terminalId: '',
                merchantId: '',
                transactionStatusId: 0,
                region: 0,
                productId: 0,
                hardwareTypeId: 0,
                issueTypeId: 0,
                cardStart: '',
                cardEnd: '',
                excludeNotInOutput: false,
            },
            isLoading: false,
        };
    },
    methods: {
        reloadTransactions() {
            this.isLoading = true;
            this.$emit('reloadTransactions', {
                query: this.searchQuery,
                done: () => {
                    this.isLoading = false;
                },
            });
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.searchQuery));
        },
        onReset(event) {
            event.preventDefault();
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        inputCardNumberFront(event) {
            const value = event.currentTarget.textContent;

            if (String(value).length <= 6) this.searchQuery.cardStart = value;
        },
        inputCardNumberBack(event) {
            const value = event.currentTarget.textContent;

            if (String(value).length <= 4) this.searchQuery.cardEnd = value;
        },
    },
};
</script>

<style>
.aligned-checkbox {
    margin-top: 15px;
}
</style>