import AuthService from "../services/auth.service"
import { eventBus } from "../event_bus"
import Security from "./auth.security"

var user
var security
try {
    user = JSON.parse(localStorage.getItem("user"))
    var jwt = JSON.parse(atob(user.access_token.split('.')[1]))
    security = Security.init(jwt)
} catch {
    user = null
    security = Security.init(null)
}

var status = {}
if (user) {
    status.loggedIn = true
}
else {
    status.loggedIn = false
}

var portals
try {
    portals = JSON.parse(localStorage.getItem("portals"))
} catch {
    portals = null
}

var portal
try {
    var p = JSON.parse(localStorage.getItem("portal"))
    for (var i = 0; i < portals.length; ++i) {
        var pp = portals[i]
        if (pp.id == p.id) {
            portal = pp
            break
        }
    }
} catch {
    portal = null
}

var portal_configuration 
try {
    portal_configuration = JSON.parse(localStorage.getItem("portal_configuration"))
}
catch {
    portal_configuration = {}
}

const initialState = {
    user: user,
    status: status,
    portals: portals,
    portal: portal,
    security: security,
    portal_configuration: portal_configuration
}

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit, dispatch }, data) {
            return AuthService.login(data).then(
                async (data) => {
                    if (data.success) {
                        commit("loginSuccess", data)
                        dispatch("fetchPortalConfiguration")
                    } else {
                        commit("loginFailure")
                    }
                    return Promise.resolve(data)
                },
                (error) => {
                    commit("loginFailure")
                    return Promise.reject(error)
                }
            )
        },
        loginAzure({commit, dispatch}, data) {
            return AuthService.loginAzure(data).then(
                async (data) => {
                    if (data.success){
                        commit("loginSuccess", data)
                        dispatch("fetchPortalConfiguration")
                    }else {
                        commit("loginFailure")
                    }
                    return Promise.resolve(data)
                },
                (error) => {
                    commit("loginFailure")
                    return Promise.reject(error)
                }
            );
        },
        logout({ commit }) {
            AuthService.logout()
            commit("logout")
            commit("clearPortalConfiguration")
        },
        setPortal({ commit }, portal) {
            commit("setPortal", portal)
        },
        refreshJwtToken({ commit }) {
            return AuthService.refreshJwtToken(localStorage.getItem("refresh_token")).then(
                async (data) => {
                    if (data.success) {
                        commit("loginSuccess", data)
                        return Promise.resolve(data)
                    } else {
                        commit("loginFailure")
                        return Promise.reject()
                    }
                },
                (error) => {
                    commit("loginFailure")
                    return Promise.reject(error)
                }
            )
        },
        setWebcodeFromUrl({ commit }) {
            if (location.hostname !== 'localhost') {
                commit("setWebcodeFromUrlHostname")
            }
            else {
                commit("setWebcodeFromUrlPath")
            }
        },
        async setPortalFromWebcode({ commit }, webcode){
            return AuthService.getPortalFromWebcode(webcode).then(
                async (portal) => {
                    if (portal !== null) {
                        commit("setPortal", portal)
                    }
                    return Promise.resolve(portal)
                }
            )
        },
        async fetchPortalConfiguration({ state, commit }) {
            if (state.portal_configuration == null || Object.keys(state.portal_configuration).length === 0) {
              try {
                AuthService.getPortalConfiguration().then(
                    (response) => {
                        commit('setPortalConfiguration', response.data);
                    }
                )
              } catch (error) {
                console.error('Could not fetch portal configuration:', error);
              }
            }
        },
        clearPortalConfiguration({commit}){
            commit('clearPortalConfiguration');
        },
        refreshPortalConfiguration({commit, dispatch}){
            commit('clearPortalConfiguration')
            dispatch('fetchPortalConfiguration')
        }
    },
    mutations: {
        loginSuccess(state, data) {
            state.status.loggedIn = true
            state.user = data.user

            var jwt = JSON.parse(atob(state.user.access_token.split('.')[1]))
            var refresh_token = state.user.refresh_token;
            var portals = JSON.parse(jwt.sys_portals)
            var selectedPortal
            if (portals.length > 1) {
                selectedPortal = portals[0]
            } else {
                selectedPortal = portals[0]
            }
            state.portals = portals
            state.portal = selectedPortal
            state.security = Security.init(jwt)

            localStorage.setItem("user", JSON.stringify(state.user))
            localStorage.setItem("portal", JSON.stringify(selectedPortal))
            localStorage.setItem("portals", JSON.stringify(portals))
            localStorage.setItem("webcode", selectedPortal.webcode)
            localStorage.setItem("refresh_token", refresh_token)
            eventBus.$emit("user_changed", state.user)
        },
        loginFailure(state) {
            state.status.loggedIn = false
            state.user = null
            state.portal = null
            state.portals = null
            state.security = Security.init(null)
            localStorage.removeItem("user")
            localStorage.removeItem("refresh_token")
            eventBus.$emit("user_changed", null)
        },
        setPortal(state, portal) {
            state.portal = portal
            localStorage.setItem("portal", JSON.stringify(portal))
            localStorage.setItem("webcode", portal.webcode)
        },
        logout(state) {
            state.status.loggedIn = false
            state.user = null
            state.portal = null
            state.portals = null
            state.security = Security.init(null)
            localStorage.removeItem("user")
            localStorage.removeItem("portal")
            localStorage.removeItem("portals")
            localStorage.removeItem("refresh_token")
            eventBus.$emit("user_changed", null)
        },
        setWebcodeFromUrlHostname() {
            localStorage.removeItem('webcode')

            if (location.hostname !== 'localhost') {
                var hostnames = location.hostname.split('.')

                if (hostnames.length >= 1) {
                    if (hostnames[0].includes('-')) {
                        var webcode = hostnames[0].split('-')[0]

                        localStorage.setItem("webcode", webcode)                   
                    }               
                }
            }
        },
        setWebcodeFromUrlPath() {

            if (location.hostname === 'localhost') {
                var pathnames = location.pathname.split('/').slice(1)

                if (pathnames.length > 1) {
                    localStorage.removeItem('webcode')
                    var webcode = pathnames[0];
                    localStorage.setItem("webcode", webcode)
                }
            }
        },
        // setPortalToStorage(portal) {
        //     localStorage.removeItem('portal')
        //     localStorage.setItem('portal', JSON.stringify(portal))
        // }
        setPortalConfiguration(state, settings){
            state.portal_configuration = settings.portal_configuration
            localStorage.setItem('portal_configuration', JSON.stringify(settings.portal_configuration))
        },
        clearPortalConfiguration(state){
            state.portal_configuration = {}
            localStorage.removeItem('portal_configuration')
        }
    }
}