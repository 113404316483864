import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from '../utils/utils';

class PromosService {

    listCoupons(promoType, startIndex, pageSize, sortOrder, code = null) {
        return axios({
            method: 'post',
            url: '/api/promos/listCoupons',
            headers: authHeader(),
            data: {
                promo_type: promoType,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder,
                code
            }
        })
    }

    listPromoPrograms(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/promos/listPromoPrograms',
            headers: authHeader(),
            data: {
                region_id: query.region,
                province_id: query.province,
                store_number: query.storeNum,
                display_active: query.displayActive,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder,
            }
        })
    }

    removeCode(id) {
        return axios({
            method: 'post',
            url: '/api/promos/removeCode',
            headers: authHeader(),
            params: {
                id
            }
        })
    }

    removePromoProgram(id) {
        return axios({
            method: 'post',
            url: '/api/promos/removePromoProgram',
            headers: authHeader(),
            params: {
                id
            }
        })
    }

    generateSingleUseCode(params) {
        return axios({
            method: 'post',
            url: '/api/promos/generateSingleUse',
            headers: authHeader(),
            data: {
                coupon_location_type: params.location.locationTypeId,
                location_kiosk_id: params.location.kioskId,
                location_store_id: params.location.storeId,
                location_region_id: params.location.regionId,
                credit_card: params.creditCard,
                expires: addTimezoneInformation(params.expires),
                comment: params.customerName,
                product_id: params.productId,
                price: params.price,
                transaction_type: params.transactionTypeId,
                discount_type: params.discountTypeId,
                code: params.promoCode ? params.promoCode : 0,
            }
        })
    }

    generateFreeItemCode(params) {
        return axios({
            method: 'post',
            url: '/api/promos/generateFreeItem',
            headers: authHeader(),
            data: {
                coupon_location_type: params.location.locationTypeId,
                location_kiosk_id: params.location.kioskId,
                location_store_id: params.location.storeId,
                location_region_id: params.location.regionId,
                credit_card: params.creditCard,
                expires: params.expires,
                comment: params.providedTo,
                product_id: params.productId,
                quantity: params.quantity,
                code: params.promoCode ? params.promoCode : 0,
            }
        })
    }

    generateMultipleUseCode(params) {
        return axios({
            method: 'post',
            url: '/api/promos/generateMultipleUse',
            headers: authHeader(),
            data: {
                coupon_location_type: params.location.locationTypeId,
                location_kiosk_id: params.location.kioskId,
                location_store_id: params.location.storeId,
                location_region_id: params.location.regionId,
                start_date: addTimezoneInformation(params.startDate),
                expires: addTimezoneInformation(params.endDate),
                number_of_uses: params.numberOfUses,
                comment: params.promoName,
                product_id: params.productId,
                transaction_type: params.transactionTypeId,
                discount_type: params.discountTypeId,
                price: params.price,
                code: params.promoCode ? params.promoCode : 0
            }
        })
    }

    checkCodeValid(params){
        return axios({
            method: 'post',
            url: '/api/promos/checkCodeValid',
            headers: authHeader(),
            params: {
                code: params.code
            }
        })
    }

    generateReimbursement(params) {
        return axios({
            method: 'post',
            url: '/api/promos/generateReimbursement',
            headers: authHeader(),
            data: {
                coupon_location_type: params.location.locationTypeId,
                location_kiosk_id: params.location.kioskId,
                location_store_id: params.location.storeId,
                location_region_id: params.location.regionId,
                comment: params.comment,
                product_id: params.productId,
                price: params.price,
            }
        })
    }

    generateTransactionCode(params) {
        return axios({
            method: 'post',
            url: '/api/promos/generateTransactionCode',
            headers: authHeader(),
            data: {
                couponLocationType: params.location.locationTypeId,
                locationKioskId: params.location.kioskId,
                locationStoreId: params.location.storeId,
                locationRegionId: params.location.regionId,
                expires: addTimezoneInformation(params.expires),
                product_id: params.productId,
                transactionType: params.transactionTypeId,
                quantity: params.quantity
            }
        })
    }

    addUpdatePromoProgram(params) {
        return axios({
            method: 'post',
            url: '/api/promos/addUpdatePromoProgram',
            headers: authHeader(),
            data: {
                name: params.name,
                start_date: params.startDate,
                end_date: params.endDate,
                fixed_expiry_date: params.fixedExpiry,
                days_to_expire: params.daysToExpiry,
                product_id: params.productId,
                transaction_type: params.transactionTypeId,
                discount_type: params.discountTypeId,
                discount: params.discount,
                receipt_message: params.receiptMessage,
                id: params.id
            }
        })
    }

    downloadCsv(promoType, redeemedOnly) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/promos/download_csv',
            headers: authHeader(),
            data: {
                promo_type: promoType,
                redeemed_only: redeemedOnly,
            }
        })
    }

    downloadXlsx(promoType, redeemedOnly) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/promos/download_xlsx',
            headers: authHeader(),
            data: {
                promo_type: promoType,
                redeemed_only: redeemedOnly,
            }
        })
    }

    downloadProgramCsv(query) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/promos/download_program_csv',
            headers: authHeader(),
            data: {
                region_id: query.region,
                province_id: query.province,
                store_number: query.storeNum,
                display_active: query.displayActive,
            }
        })
    }

    downloadProgramXlsx(query) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/promos/download_program_xlsx',
            headers: authHeader(),
            data: {
                region_id: query.region,
                province_id: query.province,
                store_number: query.storeNum,
                display_active: query.displayActive,
            }
        })
    }

    getDiscountTypes() {
        return axios({
            method: 'get',
            url: '/api/promos/discount_types',
            headers: authHeader()
        })
    }
}

export default new PromosService();