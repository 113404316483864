<template>
    <div>
        <h5>Firmware Type: {{ firmware.hardware_type }}</h5>
        <DynamicSearchComponent
            ref="kioskSearchComponent"
            preferencesName="firmware_promotion_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <div class="col promote-btn">
            <button
                @click="addKiosksToPhase"
                class="btn btn-primary row"
                :disabled="locked"
            >
                <span>Save</span>
            </button>
        </div>
        <SpinnerNoResults :isLoading="isLoading" :spinnerOnly="true" />
        <div class="pb-4">
            <Table
                id="kiosksTable"
                :fields="fields"
                :items="kiosks"
                sortBy="phase"
                :sortDesc="false"
                :searchPagination="searchPagination"
                @getItems="getKiosks"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #head(actions)>
                    <b-form-checkbox
                        :v-model="selectAll"
                        @change="toggleSelectAll"
                        :disabled="kiosks.length == 0 || locked"
                    />
                </template>
                <template #cell(actions)="data">
                    <b-form-checkbox-group v-model="selectedKiosks">
                        <b-form-checkbox
                            :value="data.item.id"
                            :disabled="locked"
                        />
                    </b-form-checkbox-group>
                </template>
                <template #cell(vmid)="data">
                    <a
                        href="#"
                        @click="kioskLink(data.item.id)"
                        class="underline"
                        >{{ data.item.vmid }}</a
                    >
                </template>
                <template #cell(phase)="data">
                    <span v-if="data.item.phase > 0">{{
                        data.item.phase
                    }}</span>
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
import FirmwareService from '../../services/firmware.service';
import SearchPagination from '../../models/search.pagination';
import {
    Table,
    DynamicSearchComponent,
    SpinnerNoResults,
} from '../../components';
import { kioskLink } from '../../utils/utils';

export default {
    name: 'KioskPromotion',
    components: {
        Table,
        DynamicSearchComponent,
        SpinnerNoResults,
    },
    props: {
        phase: {
            type: Object,
            require: true,
        },
        firmware: {
            type: Object,
            require: true,
        },
        locked: {
            type: Boolean,
            require: false,
            default: false,
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                },
                {
                    key: 'vmid',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1,
                },
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2,
                },
                {
                    key: 'store_address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'region',
                    label: 'Region',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'phase',
                    label: 'Phase Tag',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3,
                },
            ],
            kiosks: [],
            selectedKiosks: [],
            hardwareType: 0,
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: 3,
            searchCriteria: [
                {
                    key: 'belongs_to_phase',
                    name: 'Belongs To Phase',
                    type: 'Boolean',
                    searchQueryName: 'belongsToPhase',
                    defaultValue: false,
                },
                {
                    key: 'ignore_phase_tag',
                    name: 'Ignore Phase Tag',
                    type: 'Boolean',
                    searchQueryName: 'ignorePhaseTag',
                    defaultValue: false,
                    initial: true
                },
                {
                    key: 'phase',
                    name: 'Phase Tag',
                    type: 'Phase',
                    searchQueryName: 'phase',
                    defaultValue: this.phase.phase,
                    initial: true
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'requires_update_only',
                    name: 'Requires Update Only',
                    type: 'Boolean',
                    searchQueryName: 'requiresUpdateOnly',
                    defaultValue: false,
                },
            ],
            selectAll: false,
            lastQuery: {},
        };
    },
    watch: {
        selectAll() {
            if (this.selectAll) {
                this.selectAllKiosk();
            } else {
                this.deselectAll();
            }
        },
    },
    created() {},
    mounted() {
        this.getKiosks();
        this.getSelectedKiosk();
    },
    methods: {
        getKiosks() {
            this.$refs.kioskSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.lastQuery = args.query;
            this.isLoading = true;
            FirmwareService.searchKioskFirmware(
                this.phase.id,
                this.firmware.id,
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.kiosks = response.data.items;
                        this.searchPagination.totalRows =
                            this.kiosks.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                    this.isLoading = false;
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder;
            this.getKiosks();
        },
        addKiosksToPhase() {
            FirmwareService.addKiosksToPhase(
                this.phase.id,
                this.selectedKiosks
            ).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });

                    if (response.data.success) {
                        this.getKiosks();
                    }

                    this.$emit('hide');
                }
            });
        },
        toggleSelectAll() {
            this.selectAll = !this.selectAll;
        },
        selectAllKiosk() {
            this.isLoading = true;
            FirmwareService.searchKioskFirmware(
                this.phase.id,
                this.firmware.hardware_type_id,
                this.lastQuery,
                0,
                0,
                this.sortOrder
            ).then((response) => {
                if (response) {
                    var kiosks = response.data.items;
                    this.selectedKiosks = kiosks.map((kiosk) => kiosk.id);
                    this.isLoading = false;
                }
            });
        },
        deselectAll() {
            this.selectedKiosks = [];
        },
        getSelectedKiosk() {
            this.isLoading = true;
            FirmwareService.getKiosksByPhase(this.phase.id).then((response) => {
                if (response) {
                    this.selectedKiosks = response.data;
                    this.isLoading = false;
                }
            });
        },
        kioskLink,
    },
};
</script>
<style scope>
.right {
    float: right;
}
.promote-group {
    display: flex;
    align-items: center;
}
.promote-btn {
    margin-top: 1rem;
}
.date-time-group {
    display: flex;
    align-items: center;
}
.picker {
    width: 20%;
}
.underline {
    text-decoration: underline;
}
</style>