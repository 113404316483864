<template>
    <div>
        <b-form-group id="promo-name-input-group" label="Promo Name" label-for="promoName">
            <b-form-input 
                id="promo-name-input"
                v-model="multipleUseCode.promoName"
                :state="promoNameState"
            >
            </b-form-input>
        </b-form-group>
        <div v-if="promoNameState == false" class="error">
            Promo Name is required
        </div>
        <b-form-group id="promo-code-input-group" label="Promo Code" label-for="promoCode">
            <b-form-input
                id="promo-code-input"
                v-model="multipleUseCode.promoCode"
                placeholder="Optional - Random Code if Empty"
                :state="promoCodeState"
                @update="checkValidCode"
                type="number"
            >
            </b-form-input>
        </b-form-group>
        <div v-if="promoCodeState == false" class="error">
            {{this.invalidCodeMessage != "" ? this.invalidCodeMessage : 'Code is not valid'}}
        </div>
        <LocationSelectComponent v-model="multipleUseCode.location" :state="locationState"/>
        <b-form-group id="start-date-group" label="Start Date" label-for="start-date">
            <b-input-group>
                <b-form-input
                    id="start-date-input"
                    v-model="multipleUseCode.startDate"
                    placeholder="YYYY-MM-DD"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                    autocomplete="off"
                    :state="startDateState"
                    :disabled="true"
                    >
                </b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="start-date"
                        v-model="multipleUseCode.startDate"
                        button-only
                        right
                        :min="minDate" 
                        :max="maxDate"
                    >
                    </b-form-datepicker>
                </b-input-group-append>
            </b-input-group>   
        </b-form-group>
        <div v-if="startDateState == false" class="error">
            Start Date is required
        </div>
        <b-form-group id="end-date-group" label="End Date" label-for="end-date">
            <b-input-group>
                <b-form-input
                    id="end-date-input"
                    v-model="multipleUseCode.endDate"
                    placeholder="YYYY-MM-DD"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                    autocomplete="off"
                    :state="endDateState"
                    :disabled="true"
                    >
                </b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="end-date"
                        v-model="multipleUseCode.endDate"
                        button-only
                        right
                        :min="minDate" 
                        :max="maxDate"
                    >
                    </b-form-datepicker>
                </b-input-group-append>
            </b-input-group>   
        </b-form-group>
        <div v-if="endDateState == false" class="error">
            End Date is required
        </div>
        <b-form-group id="number-of-uses-input-group" label="# of Uses (0 = unlimited)" label-for="number-of-uses">
            <b-form-spinbutton 
                id="number-of-uses-input" 
                v-model="multipleUseCode.numberOfUses" 
                inline
                :state="numberOfUsesState"
                min="0"
                >
            </b-form-spinbutton>
        </b-form-group>
        <div v-if="numberOfUsesState == false" class="error">
            # of Uses is required
        </div>
        <ProductsComponent v-model="multipleUseCode.productId" :hideOptionAll="true" :state="productIdState"/>
        <div v-if="productIdState == false" class="error">
            Product is required
        </div>
        <TransactionTypeSelectComponent v-model="multipleUseCode.transactionTypeId" :state="transactionTypeIdState"/>
        <div v-if="transactionTypeIdState == false" class="error">
            Transaction Type is required and can not be 'Purchase and Exchange' if discount type is 'Fixed Price'
        </div>
        <DiscountTypeSelectComponent v-model="multipleUseCode.discountTypeId" :state="discountTypeIdState"/>
        <div v-if="transactionTypeIdState == false" class="error">
            Discount Types is required
        </div>
        <b-form-group id="price-input-group" label="Amount" label-for="price-input">
            <b-input-group>
                <b-input-group-prepend is-text>
                    <div v-if="!changeDollarIcon">
                        <font-awesome-icon icon="dollar-sign" class="nav-icon fa" />
                    </div>
                    <div v-if="changeDollarIcon">
                        <font-awesome-icon icon="percent" class="nav-icon fa" />
                    </div>
                </b-input-group-prepend>
                <b-form-input 
                    id="price-input" 
                    v-model="multipleUseCode.price" 
                    :state="priceState"
                ></b-form-input>
            </b-input-group>
        </b-form-group>
        <div v-if="priceState == false" class="error">
            Price required
        </div>
        <b-button @click="generateMultipleUseCode" variant="primary" class="right">Add</b-button>
    </div>
</template>
<script>

import PromosService from "../../services/promos.service"
import { ProductsComponent, TransactionTypeSelectComponent, LocationSelectComponent, DiscountTypeSelectComponent } from "../../components"
import { getDateOnly } from "../../utils/utils"

export default {
    name: "AddMultipleUseCode",
    components: {
        ProductsComponent,
        TransactionTypeSelectComponent, 
        LocationSelectComponent,
        DiscountTypeSelectComponent
    },
    data() {
        return {
            multipleUseCode:{
                promoName: "",
                promoCode: "",
                location: {},
                numberOfUses: 0,
                startDate: "",
                endDate: "",
                productId: 0,
                transactionTypeId: 0,
                price: 0.0,
                discountTypeId: 0
            },
            minDate: {},
            maxDate: {},
            checkValid: false,    
            changeDollarIcon: false,
            validCode: true,
            invalidCodeMessage: ""  
        };
    },
    computed: {
        promoNameState() {
            if (!this.checkValid) return null
            return this.multipleUseCode.promoName != ""
        },
        locationState() {
            if (!this.checkValid) return null
            return Object.keys(this.multipleUseCode.location).length != 0
        },
        numberOfUsesState() {
            if (!this.checkValid) return null
            return  this.multipleUseCode.numberOfUses > -1
        },
        startDateState() {
            if (!this.checkValid) return null
            return this.multipleUseCode.startDate != ""
        },
        endDateState() {
            if (!this.checkValid) return null
            return this.multipleUseCode.endDate != ""
        },
        productIdState() {
            if (!this.checkValid) return null
            return this.multipleUseCode.productId > 0
        },
        transactionTypeIdState() {
            if (!this.checkValid) return null
            if (this.multipleUseCode.discountTypeId == 1 && this.multipleUseCode.transactionTypeId == 6) return false;
            return this.multipleUseCode.transactionTypeId > 0
        },
        discountTypeIdState() {
            this.checkDiscountType();
            if (!this.checkValid) return null
            return this.multipleUseCode.discountTypeId > 0
        },
        priceState() {
            if (!this.checkValid) return null
            return  this.multipleUseCode.price > 0
        },
        promoCodeState(){
            if (this.multipleUseCode.promoCode == "") return null
            if (this.validCode && !this.checkValid) return null
            return this.validCode;
        },
        isFormValid() {
            return this.promoNameState && this.locationState && this.numberOfUsesState && this.startDateState && this.endDateState && this.productIdState && this.transactionTypeIdState && this.priceState && this.discountTypeIdState
        }
    },
    created() {
        this.setUpDates();
    },
    methods: {
        setUpDates() {
            const now = new Date()

            this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            this.maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())
            var weekFomNow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)

            this.multipleUseCode.startDate = this.getDateOnly(this.minDate)
            this.multipleUseCode.endDate = this.getDateOnly(weekFomNow)
        },
        getDateOnly,
        generateMultipleUseCode() {
            this.checkValid = true
            if (this.isFormValid) {
                PromosService.generateMultipleUseCode(this.multipleUseCode).then((response) => {
                    if (response) {   
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.$emit("hide")
                    }
                })
            }
        },
        checkDiscountType() {
            if (this.multipleUseCode.discountTypeId == 2) {
                this.changeDollarIcon = true;
            }
            else {
                this.changeDollarIcon = false;
            }
        },
        checkValidCode(value){
            this.validCode = true;
            this.invalidCodeMessage = "";
            PromosService.checkCodeValid({code: value}).then((response) =>
                {
                    this.validCode = response.data.valid;
                    if (response.data.message)
                        this.invalidCodeMessage = response.data.message;
                }
            )
        }
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>