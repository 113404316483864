import { auth } from '../modules/auth.module'
import { vueInst } from "../main"

export default function authHeader() {
    const user = auth.state.user

    var result = {}
    if (user && user.access_token) {
        result.Authorization = 'Bearer ' + user.access_token

        let portalid = vueInst?.$root.$route.query.portal_id
        result.portal_id = portalid ?? auth.state.portal.id        
    }
    
    const webcode = localStorage.getItem("webcode");

    if (webcode) {
        result.webcode = webcode
    }

    return result
}