import axios from "axios";
import authHeader from "./auth.header";

class FirmwareService {

    getFirmwareTypes() {
        return axios({
            method: 'get',
            url: '/api/firmware/get_firmware_types',
            headers: authHeader(),
        })
    }

    searchKioskFirmware(phaseId, firmwareId, query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/firmware/search_kiosk_firmware',
            headers: authHeader(),
            data: {
                phase_id: phaseId,
                firmware_id: firmwareId,
                vmid: query.vmid,
                region_id: query.region,
                product_id: query.product,
                requires_update_only: query.requiresUpdateOnly,
                phase: query.phase,
                ignore_phase_tag: query.ignorePhaseTag,
                belongs_to_phase: query.belongsToPhase,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    getKiosksByPhase(phaseId) {
        return axios({
            method: 'get',
            url: '/api/firmware/get_kiosk_by_phase',
            headers: authHeader(),
            params: {
                phaseId
            }
        })
    }

    addKiosksToPhase(phaseId, selectedKiosks) {
        return axios({
            method: 'post',
            url: '/api/firmware/add_kiosks_to_phase',
            headers: authHeader(),
            data: {
                phase_id: phaseId,
                selected_kiosks: selectedKiosks,
            }
        })
    }
}
export default new FirmwareService();