<template>
    <div>
        <div v-if="phase.phase > 1">
            <p>
                Are you sure you want to add a new phase to this release?
                <br/>
                Adding a new phase will lock previous phases from being edited
            </p>
            <hr/>
        </div>
        <div>
            <ul class="phase-info">
                <li>Firmware: {{firmware.hardware_type}}</li>
                <li>Release: {{firmware.version}}</li>
                <li>Phase #{{phase.phase}}</li>
            </ul>
        </div>
        <b-form-group id="update-date-datepicker-group" label="Update On" label-for="update-date-datepicker" class="datepicker">
            <div class="date-time-group">
                <div>
                    <b-form-datepicker
                        id="update-date-datepicker"
                        v-model="updateDate"
                        right
                        :min="minDate"
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit'}"
                        class="mr-3"
                    />
                </div>
                <div>
                    <b-form-timepicker
                        id="update-date-timepicker"
                        v-model="updateTime"
                        right
                        class="ml-3"
                    />
                </div>
            </div>
        </b-form-group>
        <div class="col promote-btn">
            <button @click="setPhase" class="btn btn-primary row right">Save</button>
        </div>
    </div>
</template>

<script>
import { getDateOnly, getTimeOnly, addTimezoneInformation, convertUTCtoLocalDate } from "../../utils/utils"
import SoftwareService from "../../services/software.service"

export default {
    name: "AddEditPhase",
    components: {
    },
    props: {
        phase: {
            type: Object,
            require: true
        },
        firmware: {
            type: Object,
            require: true
        },
        isEdit: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {
            updateDate: "",
            updateTime: "00:00:00",
            minDate: "",
        }
    },
    computed: {
        updateDatetime() {
            return `${this.updateDate} ${this.updateTime}`
        }
    },
    created() {
        this.setDates()
    },
    mounted() {
    },
    methods: {
        setDates(){
            if (!this.isEdit) {
                this.updateDate = this.getDateOnly(new Date())
            }
            else {
                this.updateDate = this.getDateOnly(convertUTCtoLocalDate(this.phase.update_on))
                this.updateTime = this.getTimeOnly(convertUTCtoLocalDate(this.phase.update_on))
            }
            
            this.minDate = this.getDateOnly(new Date())
        },
        setPhase() {         
            SoftwareService.setPhase(
                this.phase.id,
                this.phase.firmware_id,
                this.phase.phase,
                addTimezoneInformation(this.updateDatetime),
            ).then(response => {
                if (response) {
                     this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success?  'success' : 'danger',
                        solid: true
                    })
                    
                    this.$emit('hide', response.data.phase_id, response.data.new_phase)
                }
            })
        },
        getDateOnly,
        getTimeOnly,
        addTimezoneInformation,
        convertUTCtoLocalDate
    }
}
</script>

<style scoped>
.phase-info {
    list-style-type: none;
    padding-left: 0;
}
</style>