class SecurityManager {
    constructor(jwt) {
        if (!jwt) {
            this.jwt = {}
        }
        else {
            this.jwt = jwt
        }
    }

    get technician() {
        return this.hasValue(this.jwt.technician, "x")
    }

    get hasTwoFaEnabled() {
        return this.hasValue(this.jwt.sys_reqTwoFa, "True")
    }

    get kioskRead() {
        return this.hasValue(this.jwt.kiosks, "r")
    }

    get kioskWrite() {
        return this.hasValue(this.jwt.kiosks, "w")
    }

    get kioskCreate() {
        return this.hasValue(this.jwt.kiosks, "c")
    }

    get kioskDelete() {
        return this.hasValue(this.jwt.kiosks, "d")
    }

    get kioskDetailsRead() {
        return this.hasValue(this.jwt.kiosk_details, "r")
    }

    get kioskExtendedDetailsRead() {
        return this.hasValue(this.jwt.kiosk_extended_details, "r")

    }

    get kioskInventoryRead() {
        return this.hasValue(this.jwt.kiosk_inventory, "r")

    }

    get kioskIssuesRead() {
        return this.hasValue(this.jwt.kiosk_issues, "r")

    }

    get kioskDiagnosticMaintenanceRead() {
        return this.hasValue(this.jwt.kiosk_diagnostic_maintenance, "r")

    }

    get kioskConfigurationRead() {
        return this.hasValue(this.jwt.kiosk_configuration, "r")

    }

    get kioskQRCodeRead() {
        return this.hasValue(this.jwt.kiosk_qrcode, "r")
    }

    get transactionRead() {
        return this.hasValue(this.jwt.transactions, "r")
    }

    get transactionLogsRead() {
        return this.hasValue(this.jwt.transaction_logs, "r")
    }

    get refundRead() {
        return this.hasValue(this.jwt.refunds, "r")
    }

    get refundCreate() {
        return this.hasValue(this.jwt.refunds, "c")
    }

    get codeActivityRead() {
        return this.hasValue(this.jwt.code_activity, "r")
    }

    get commissionReportRead() {
        return this.hasValue(this.jwt.commission_report, "r")
    }

    get maintenanceLogsRead() {
        return this.hasValue(this.jwt.maintenance_logs, "r")
    }

    get problemsRead() {
        return this.hasValue(this.jwt.kiosks, "r")
    }

    get firmwareRead() {
        return this.hasValue(this.jwt.firmware, "r")
    }

    get softwareRead() {
        return this.hasValue(this.jwt.software, "r")
    }

    get softwareWrite() {
        return this.hasValue(this.jwt.software, "w")
    }

    get softwareCreate() {
        return this.hasValue(this.jwt.software, "c")
    }

    get softwareDelete() {
        return this.hasValue(this.jwt.software, "d")
    }

    get hasSystems() {
        return this.userRead
        || this.roleRead 
        || this.eventRead
        || this.customFieldsRead
        || this.codeActivityRead 
        || this.auditRead
        || this.regionsRead
    }

    get hasFleet() {
        return this.kioskRead
        || this.servantRead
        || this.maintenanceLogsRead
        || this.problemsRead 
    }

    get hasBusiness() {
        return this.storeRead 
        || this.transactionRead 
        || this.refundRead 
        || this.productRead
    }

    get hasPromos() {
        return this.promoRead
    }

    get hasReports() {
        return this.uptimeRead 
        || this.saleRead 
        || this.commissionReportRead 
        || this.fileArchiveRead
        || this.firmwareRead
    }

    get hasFirmware() {
        return this.softwareRead 
        || this.firmwareRead
    }

    get hasKioskDetails() {
        return this.kioskDetailsRead 
        || this.kioskExtendedDetailsRead
        || this.kioskInventoryRead
        || this.kioskIssuesRead
        || this.kioskDiagnosticMaintenanceRead
        || this.kioskConfigurationRead
        || this.kioskQRCodeRead
        || this.kioskTrustRead
        || this.firmwareRead
        || this.relayRead
        || this.tagRead
        || this.servantRead
    }

    get promoRead() {
        return this.hasValue(this.jwt.promo, "r")
    }

    get promoWrite() {
        return this.hasValue(this.jwt.promo, "w")
    }

    get promoCreate() {
        return this.hasValue(this.jwt.promo, "c")
    }

    get promoDelete() {
        return this.hasValue(this.jwt.promo, "d")
    }

    get storeRead() {
        return this.hasValue(this.jwt.stores, "r")
    }

    get storeWrite() {
        return this.hasValue(this.jwt.stores, "w")
    }

    get storeCreate() {
        return this.hasValue(this.jwt.stores, "c")
    }

    get storeDelete() {
        return this.hasValue(this.jwt.stores, "d")
    }

    get eventRead() {
        return this.hasValue(this.jwt.events, "r")
    }

    get eventWrite() {
        return this.hasValue(this.jwt.events, "w")
    }

    get eventCreate() {
        return this.hasValue(this.jwt.events, "c")
    }

    get eventDelete() {
        return this.hasValue(this.jwt.events, "d")
    }

    get roleRead() {
        return this.hasValue(this.jwt.role, "r")
    }

    get roleWrite() {
        return this.hasValue(this.jwt.role, "w")
    }

    get roleCreate() {
        return this.hasValue(this.jwt.role, "c")
    }

    get roleDelete() {
        return this.hasValue(this.jwt.role, "d")
    }

    get saleRead() {
        return this.hasValue(this.jwt.sales, "r")
    }

    get driverCodeRead() {
        return this.hasValue(this.jwt.driver_code, "r")
    }

    get driverCodeWrite() {
        return this.hasValue(this.jwt.driver_code, "w")
    }

    get driverCodeCreate() {
        return this.hasValue(this.jwt.driver_code, "c")
    }

    get uptimeRead() {
        return this.hasValue(this.jwt.uptime, "r")
    }

    get userRead() {
        return this.hasValue(this.jwt.user, "r")
    }

    get userWrite() {
        return this.hasValue(this.jwt.user, "w")
    }

    get userCreate() {
        return this.hasValue(this.jwt.user, "c")
    }

    get userDelete() {
        return this.hasValue(this.jwt.user, "d")
    }

    get apiLoginRead() {
        return this.hasValue(this.jwt.api_login, "r")
    }

    get apiLoginCreate() {
        return this.hasValue(this.jwt.api_login, "c")
    }

    get apiLoginDelete() {
        return this.hasValue(this.jwt.api_login, "d")
    }

    get fileArchiveRead() {
        return this.hasValue(this.jwt.file_archive_data, "r")
    }

    get productRead() {
        return this.hasValue(this.jwt.products, "r")
    }

    get productWrite() {
        return this.hasValue(this.jwt.products, "w")
    }

    get productCreate() {
        return this.hasValue(this.jwt.products, "c")
    }

    get productDelete() {
        return this.hasValue(this.jwt.products, "d")
    }

    get tagRead() {
        return this.hasValue(this.jwt.tags, "r")
    }

    get tagWrite() {
        return this.hasValue(this.jwt.tags, "w")
    }

    get tagCreate() {
        return this.hasValue(this.jwt.tags, "c")
    }

    get tagDelete() {
        return this.hasValue(this.jwt.tags, "d")
    }

    get relayRead() {
        return this.hasValue(this.jwt.remote_access, "r")
    }

    get kioskTrustRead() {
        return this.hasValue(this.jwt.kiosk_trust, "r")
    }

    get kioskTrustCreate() {
        return this.hasValue(this.jwt.kiosk_trust, "c")
    }

    get kioskTrustDelete() {
        return this.hasValue(this.jwt.kiosk_trust, "d")
    }

    get logRead() {
        return this.hasValue(this.jwt.log_data, "r")
    }

    get phaseRead() {
        return this.hasValue(this.jwt.phase, "r")
    }

    get phaseWrite() {
        return this.hasValue(this.jwt.phase, "w")
    }

    get phaseCreate() {
        return this.hasValue(this.jwt.phase, "c")
    }

    get phaseDelete() {
        return this.hasValue(this.jwt.phase, "d")
    }

    get auditRead() {
        return this.hasValue(this.jwt.audit, "r")
    }

    get requiresTwoFa() {
        return this.hasValue(this.jwt.requires_two_fa, "r")
    }

    get servantRead() {
        return this.hasValue(this.jwt.servant, "r")
    }

    get servantWrite() {
        return this.hasValue(this.jwt.servant, "w")
    }

    get servantCreate() {
        return this.hasValue(this.jwt.servant, "c")
    }

    get servantDelete() {
        return this.hasValue(this.jwt.servant, "d")
    }

    get customFieldsRead() {
        return this.hasValue(this.jwt.custom_fields, "r")
    }

    get customFieldsWrite() {
        return this.hasValue(this.jwt.custom_fields, "w")
    }

    get customFieldsCreate() {
        return this.hasValue(this.jwt.custom_fields, "c")
    }

    get customFieldsDelete() {
        return this.hasValue(this.jwt.custom_fields, "d")
    }

    get regionsRead() {
        return this.hasValue(this.jwt.regions, "r")
    }

    get regionsWrite() {
        return this.hasValue(this.jwt.regions, "w")
    }

    get regionsCreate() {
        return this.hasValue(this.jwt.regions, "c")
    }

    get regionsDelete() {
        return this.hasValue(this.jwt.regions, "d")
    }

    get portalConfigRead(){
        return this.hasValue(this.jwt.portal_configuration, "r")
    }

    get portalConfigWrite(){
        return this.hasValue(this.jwt.portal_configuration, "w")
    }

    get portalImagesRead(){
        return this.hasValue(this.jwt.portal_images, "r")
    }

    get portalImagesWrite(){
        return this.hasValue(this.jwt.portal_images, "w")
    }

    hasValue(p, v) {
        if (Array.isArray(p)) {
            return p.includes(v)
        }
        return p == v
    }

    hasAccess(routeAccess) {
        const access = routeAccess.split('-')
        if (Array.isArray(this.jwt[access[0]])) {
            return this.jwt[access[0]].includes(access[1])
        }
        return this.jwt[access[0]] == access[1]
    }
}

export default {
    init(jwt) {
        return new SecurityManager(jwt)
    }
}