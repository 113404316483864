<template>
    <div>
    <h3>
        <font-awesome-icon icon="gears" class="nav-icon fa" /> Portal Images
        <!-- TODO: Add back user help guide with split HTML 
        <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf9')" class="help-icon nav-icon fa" title="Sleegers User Guide - Portal Configuration"/> -->
    </h3>
      <form @submit.prevent="uploadImage">
        <div>
            <label for="image">Image:</label>
            <b-form-file 
                v-model="image" 
                placeholder="Select an image or drop it here"
                drop-placeholder="Drop image Here"
                accept=".png,.jpg,.jpeg"
            />
        </div>

        <div>
          <label for="image_type">Image Type:</label>
          <b-form-select
            :id="image_type"
            v-model="image_type"
        >
            <option
                v-for="imageType in imageTypes"
                :value="String(imageType.id)"
                :key="imageType.id"
            >
                {{ imageType.name }}
            </option>
        </b-form-select>
        </div>
  
        <div>
            <label for="product_id">Product (Optional):</label>
            <b-form-select
                :id="product_id"
                v-model="product_id"
            >
                <option :value="0">None</option>
                <option
                    v-for="product in products"
                    :value="String(product.id)"
                    :key="product.id"
                >
                    {{ product.description }}
                </option>
            </b-form-select>
        </div>
  
        <b-button variant='primary' class="btn btn-primary" type="submit">Upload</b-button>
      </form>
  
      <div v-if="responseMessage" class="response-message">
        {{ responseMessage }}
      </div>
    </div>
  </template>
  
 
  <script>

  import ImagesService from "../../services/images.service";
  import ProductsService from "../../services/products.service";

  export default {
    data() {
      return {
        imageTypes: [Object],
        image: null,
        image_type: "0",
        product_id: "0",
        responseMessage: "",
        products: [Object]
      };
    },
    mounted(){
        this.getImageTypes();
        this.getProducts();
    },
    methods: {
      handleFileChange(event) {
        this.image = event.target.files[0];
      },
      async uploadImage() {
        if (!this.image) {
          this.responseMessage = "Please select an image to upload.";
          return;
        }
  
        // Create FormData to hold the file and other parameters
        const formData = new FormData();
        formData.append("image", this.image);
  
        try {
            await ImagesService.UploadImage(formData, this.image_type, this.product_id);
            this.responseMessage = "Image uploaded successfully!";
        } catch (error) {
            console.error("Error uploading image:", error);
            this.responseMessage = error.response?.data?.message || "An error occurred while uploading the image.";
        }
      },
      getImageTypes() {
            ImagesService.getImageTypes()
                .then((response) => {
                    this.imageTypes = response.data;
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'ImagesService.getImageTypes failed'
                    );
                });
        },
        getProducts() {
            ProductsService.get()
                .then((response) => {
                    this.products = response.data;
                })
                .catch((error) => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'ProductsService.getPrices failed',
                    variant: 'danger',
                        solid: true,
                    });
                });
        },
    }
  };
  </script>
  
  <style scoped>
  /* Basic styling for the component */
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
  }
  
  label {
    font-weight: bold;
  }
  
  button {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .response-message {
    margin-top: 20px;
    font-size: 1.2em;
    color: green;
  }
  </style>
  