<template>
    <div class="row d-flex justify-content-center">
        <div class="col-md-6 signin-input" >
            <div v-if="portalimage">
                <img class="title text-center" style='max-width:100%; min-width:100%; padding-bottom: 20px' v-bind:src="portalimage">
            </div>
            <div v-else>
                <h1 class="title text-center">Sign In 
                    <span v-if="portalname">
                        - {{portalname}}
                    </span>
                    <span v-else-if="webcode">
                        - {{webcode}}
                    </span>
                </h1>
            </div>
            <form class="signin-input-form" autocomplete="off" name="form" @submit.prevent="performLogin">
                <b-form-group
                    class="signin-input"
                    id="input-group-1"
                    label="Username"
                    label-for="username">

                    <b-form-input v-model="username"
                        type="text"
                        class="form-control"
                        id="username"
                        name="username" 
                        autocomplete="on"/>

                </b-form-group>
                <b-form-group
                    id="input-group-2"
                    label="Password"
                    label-for="password"
                    class="signin-input">

                    <b-form-input readonly
                        onfocus="this.removeAttribute('readonly');"
                        v-model="password"
                        type="password"
                        class="form-control"
                        id="password"
                        name="password" 
                        autocomplete="on"/>
                </b-form-group>
                <b-form-group
                    id="input-group-3"
                    label="Authenticator Code"
                    label-for="authenticatorcode"
                    class="signin-input"
                    v-if="authCodeRequest">

                    <b-form-input 
                        v-model="authCode"
                        type="text"
                        class="form-control"
                        id="authenticatorcode"
                        name="authenticatorcode" />
                </b-form-group>
                <div class="group right">
                    <div>
                        <SpinnerButton :isLoading="loading" text="Sign In" class="button-margin"/>
                    </div>
                </div>
                <div class="mt-3">
                <button @click="signInWithAzure">
                    <img src="../../assets/users/ms-symbollockup_signin_light.png">
                </button>
            </div>
            </form>
            <div class="alert alert-danger mt-3" role="alert" v-if="!webcode">
                Webcode is missing
            </div>
        </div>
    </div>
</template>

<script>
    import { UsersService } from "../../services"; 
    import SpinnerButton from "../../components/SpinnerButton.vue";
    import { isMobile } from "../../utils/utils";

    export default {
        name: "Login",
        components: {
            SpinnerButton,
        },
        data() {
            return {
                username: "",
                password: "",
                authCode: "",
                authCodeRequest: false,
                loading: false,
                portalname: "",
                portalimage: ""
            };
        },
        computed: {
            webcode() {
                return localStorage.getItem('webcode');
            }
        },
        created() {
            this.portalname = ""
            this.getWebcode()
        },
        async mounted() {
            const response = await this.$msal.handleRedirectPromise();
            if (response !== null && response.state) {
                const state = JSON.parse(response.state);
                if (state.action === "login") {
                    this.$store.dispatch("auth/loginAzure", 
                        response.idToken
                    ).then(
                        (response) => {
                            this.onLogin(response);
                        },
                        (error) => {
                            this.onLoginError(error);
                        }
                    );
                }
                else if (state.action == "createAccount") {
                    if (response){
                        UsersService.inviteRegisterAzure(state.inviteToken, response.idToken).then(
                            (response) => {
                                this.checkValid = false
                                this.$root.$bvToast.toast(response.data.message, {
                                    title: response.data.success ? 'Success' : 'Error',
                                    variant: response.data.success?  'success' : 'danger',
                                    solid: true
                                })
                                if (response.data.success) {
                                    this.$router.push('/login');
                                }
                            }
                        );
                    }
                }
            }

            this.portalname = ""
            this.getWebcode()
        },
        methods: {
            async performLogin() {

                if (this.username && this.password) {
                    this.loading = true;
                    this.$store
                        .dispatch("auth/login", {
                            username: this.username,
                            password: this.password,
                            authCode: this.authCode
                        })
                        .then(
                            (response) => {
                                this.onLogin(response);
                            },
                            (error) => {
                                this.onLoginError(error);
                            }
                        );
                }
            },
            async signInWithAzure(){

                let redirectUri = "";

                if(isMobile()){
                    redirectUri = `${window.location.origin}/login`
                }
                else {
                    redirectUri = `${window.location.origin}/`
                }

                const loginRequest = {
                    scopes: ["User.Read"],
                    redirectUri: redirectUri,
                    state: JSON.stringify({ action: "login" })
                };
                
                if (isMobile()){
                    this.$msal.loginRedirect(loginRequest);
                }
                else {
                    await this.$msal.loginPopup(loginRequest)
                        .then((response) => {
                        if (response){
                            this.$store.dispatch("auth/loginAzure", 
                                response.idToken
                            ).then(
                                (response) => {
                                    this.onLogin(response);
                                },
                                (error) => {
                                    this.onLoginError(error);
                                }
                            );
                        }
                    }).catch((error) => {
                        console.error("Error handling redirect:", error)
                    })
                }
            },
            onLogin(response) {
                if (response.success) {
                    const redirectTo = this.$route.query.redirect_to
                    if (redirectTo && redirectTo !== "/") {
                        this.$router.push(redirectTo)
                    } else {
                        this.$router.push('/home')
                    }
                } else {
                    if (response.requires_totp_fa) {
                        if (!this.authCodeRequest) {
                            this.authCodeRequest = true
                        }
                        else {
                            this.$root.$bvToast.toast("Invalid authenticator code value", {
                                title: "Unable to log in", 
                                variant:"warning"
                            })
                        }
                    } else {
                        if (response.message) {
                            this.$root.$bvToast.toast(response.message, {
                                title: "ERROR", 
                                variant:"danger"
                            })
                        } else {
                            this.$root.$bvToast.toast("Invalid login credentials", {
                                title: "Unable to log in", 
                                variant:"warning"
                            })
                        }
                    }
                    this.loading = false;
                }
            },
            onLoginError(error) {
                this.loading = false;
                var message = "Unknown Error"
                switch (error.toString()) {
                    case "TypeError: Cannot read property 'status' of undefined":
                        message = "Cannot connect to server";
                        break;
                    default:
                        message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        break;
                }
                this.$root.$bvToast.toast(message, {
                    title: "ERROR", 
                    variant:"danger"
                })                
            },
            getWebcode() {
                this.$store.dispatch("auth/setWebcodeFromUrl").then(
                    this.$store.dispatch("auth/setPortalFromWebcode", this.webcode).then(portal => {
                        this.portalname = portal.name
                        this.portalimage = portal.portalImage
                    })
                )
            }
        }
    }
</script>
<style scoped>
.group {
    display: flex;
    justify-content: space-between;
}
.right {
    float: right;
}
.button-margin {
    margin-top: 0rem !important;
}
.signin-input {
    max-width: 500px;
}

.signin-input-form {
    max-width: 475px;
    padding-left: 25px;
    padding-right: 15px;
}
</style>
