<template>
    <div data-app>
        <div class="access-update-modal">
            <b-tabs lazy>
                <b-tab
                    title="Region Access"
                    class="region-access"
                    @click="onTabChange"
                    v-if="security.regionsRead"
                >
                    <div class="col">
                        <v-autocomplete
                            :items="regions"
                            v-model="regionAccess"
                            chips
                            deletable-chips
                            multiple
                            clearable
                            item-text="fullDescription"
                            item-value="id"
                            label="Region: "
                        >
                            <template v-slot:chip="{ props, item }">
                                <v-chip v-bind="props" :text="item"></v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </b-tab>
                <b-tab
                    title="Store Access"
                    class="store-access"
                    @click="onTabChange"
                    v-if="security.storeRead"
                >
                    <div class="col">
                        <v-autocomplete
                            :items="storesFullDescription"
                            v-model="storeAccess"
                            chips
                            deletable-chips
                            multiple
                            clearable
                            item-text="storeText"
                            item-value="id"
                            label="Store: "
                        >
                            <template v-slot:chip="{ props, item }">
                                <v-chip v-bind="props" :text="item"></v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <div class="d-flex justify-content-end">
            <b-button
                class="access-button"
                variant="primary"
                @click="updateUserAccess"
                >Update</b-button
            >
        </div>
    </div>
</template>


<script>
import { RegionsService, StoresService, UsersService } from '../../services';

export default {
    name: 'UpdateAccessModal',
    data() {
        return {
            access: [],
            regions: [],
            regionAccess: [],
            stores: [],
            storeAccess: [],
            success: false,
        };
    },
    computed: {
        storesFullDescription() {
            return this.stores.map((store) => {
                const storeText =
                    store.number +
                    ', ' +
                    store.description +
                    ', ' +
                    store.city +
                    ', ' +
                    store.province.abbr;
                return Object.assign({}, store, { storeText });
            });
        },
        security() {
            return this.$store.state.auth.security
        },
    },
    emits: ['changeComplete'],
    props: {
        userId: Number,
        userType: String
    },
    mounted() {
        if (this.security.regionsRead) {
            this.getRegions();
        }

        if (this.security.storeRead) {
            this.getStores();
        }
        this.getUserAccess();
    },
    methods: {
        getRegions() {
            RegionsService.get()
                .then((response) => {
                    this.regions = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'RegionsService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        getStores() {
            StoresService.get()
                .then((response) => {
                    this.stores = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'StoresService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        getUserAccess() {

            var exec = UsersService.getUserAccess;

            if (this.userType == 'API'){
                exec = UsersService.getAPIUserAccess;
            }

            exec(this.userId)
                .then((response) => {
                    this.regionAccess = response.data
                        .map((r) => r.regionId)
                        .filter(function (val) {
                            return val !== 0;
                        });
                    this.storeAccess = response.data
                        .map((s) => s.storeId)
                        .filter(function (val) {
                            return val !== 0;
                        });
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'UsersService.getUserAccess failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        updateUserAccess() {

            var exec = UsersService.updateUserAccess;

            if (this.userType == 'API'){
                exec = UsersService.updateAPIUserAccess;
            }

            exec(
                this.userId,
                this.regionAccess,
                this.storeAccess
            ).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? "Success" : "Error",
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })

                    this.$bvModal.hide('access-update-modal')
                    this.$emit('changeComplete')
                }
            });
        },
        onTabChange() {},
    },
};
</script>

<style scoped>
.inner-tab {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 15px;
}
.update-access-region .form-group {
    max-width: 80ch;
}
.update-access-region {
    max-width: 800px;
    margin-top: 15px;
    margin-left: 20px;
}
.update-access-store {
    max-width: 800px;
    margin-top: 15px;
    margin-left: 20px;
}
.access-button {
    margin: 5px;
}
.submit {
    transition: visibility 0s, opacity 0.5s linear;
}
.row {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>