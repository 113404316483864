<template>
    <div>
        <h3>
            <font-awesome-icon icon="user-gear" class="nav-icon fa" />  API Logins
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf19')" class="help-icon nav-icon fa" title="Sleegers User Guide - API Logins"/>
        </h3>
        <div class="button-group">
            <div class="button-col">
                <button
                    class="mt-2 btn btn-primary button"
                    @click="openRegisterModal"
                    v-if="security.apiLoginCreate"
                >
                    Add
                </button>
            </div>
        </div>
        <DynamicSearchComponent
            ref="apiLoginsSearchComponent"
            preferencesName="api_logins_search"
            :allCriteria="searchCriteria"
            @reloadDynamicSearch="reloadDynamicSearch"
          />
        <div class="pb-4">
            <Table
                id="apiLoginsTable"
                :fields="fields"
                :items="apiLogins"
                sortBy=""
                :sortDesc="true"
                :searchPagination="searchPagination"
                @getItems="getApiLogins"
            >
                <template #cell(actions)="row">
                    <div class="icons">
                        <font-awesome-icon icon="user-secret" class="nav-icon pointer mx-2" @click="copySecret(row.item.secret)" title="Copy Secret To Clipboard"/>
                        <font-awesome-icon
                                icon="id-badge"
                                @click="
                                    openUpdateAccessModal(
                                        row.item,
                                        row.index,
                                        $event.target
                                    )
                                "
                                v-if="security.userWrite && (security.regionsRead || security.storeRead)"
                                class="nav-icon pointer mx-2"
                                title="Access"
                            />
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" @click="openDeleteModal(row.item)" v-if="security.apiLoginDelete" title="Delete"/>
                    </div>
                </template>
                <template #cell(key)="row">
                    <span class="blur">
                        {{ row.item.key }}
                    </span>
                </template>
            </Table>
            <RegisterApiLoginModal @hide="hideRegisterApiLogin" />
            <DeleteApiLoginModal :apiLogin="selectedApiLogin" @hide="deleteComplete" />
            <b-modal
                id="access-update-modal"
                ref="access-update-modal"
                hide-footer
            >
                <template #modal-title>Update Access</template><UpdateAccessModal :userId="selectedApiLogin" :userType="'API'" @changeComplete="hideUpdateAccessModal" />
            </b-modal>
        </div>
    </div>
</template>

<script>
import { DynamicSearchComponent, Table } from '../../components';
import { AuthService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import DeleteApiLoginModal from '../../components/auth/DeleteApiLoginModal.vue';
import RegisterApiLoginModal from '../../components/auth/RegisterApiLoginModal.vue';
import { openHelpWindow } from '../../utils/utils'
import { UpdateAccessModal } from '../../components/users';

export default {
    name: 'ApiLogins',
    components: {
        DynamicSearchComponent,
        Table,
        DeleteApiLoginModal,
        UpdateAccessModal,
        RegisterApiLoginModal
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'column',
                    sortable: true,
                },
                {
                    key: 'key',
                    label: 'Key',
                    tdClass: 'key-column',
                    sortable: true,
                },
                {
                    key: 'role',
                    label: 'Role',
                    tdClass: 'column',
                    sortable: true,
                },
            ],
            searchCriteria: [
                {
                    key: 'name',
                    name: 'Name',
                    type: 'Text',
                    searchQueryName: 'name',
                },
                {
                    key: 'role',
                    name: 'Role',
                    type: 'Roles',
                    searchQueryName: 'role_id',
                    searchQueryValueProperty: 'id'
                },
            ],
            apiLogins: [],
            selectedApiLogin: 0,
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
    },
    mounted() {
            this.getApiLogins();
    },
    methods: {
        openHelpWindow,
        getApiLogins() {
            this.$refs.apiLoginsSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.isLoading = true;
            AuthService.searchApiLogins(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then(
                (response) => {
                    if (response) {
                        this.apiLogins = response.data.items
                        this.searchPagination.totalRows = this.apiLogins.length > 0 ? response.data.total_rows : 0
                        this.isLoading = false;
                        args.done();
                    }
                },
                (error) => {
                    console.log(error);
                    args.done();
                }
            );
        },
        openRegisterModal() {
            this.$bvModal.show('api-login-register-modal');
        },
        openUpdateAccessModal(item) {
            this.selectedApiLogin = item.id;
            this.$bvModal.show('access-update-modal');
        },
        hideRegisterApiLogin() {
            this.$bvModal.hide('api-login-register-modal');
            this.getApiLogins();
        },
        hideUpdateAccessModal() {
            this.$bvModal.hide('access-update-modal');
            this.isLoading = false;
            this.getApiLogins();
        },
        openDeleteModal(item) {
            this.selectedApiLogin = item.id;
            this.$bvModal.show('api-login-delete-modal');
        },
        deleteComplete() {
            this.$bvModal.hide('api-login-delete-modal');
            this.getApiLogins();
        },
        copyKey(key) {
            navigator.clipboard.writeText(key);

            this.$root.$bvToast.toast('Key Copied To Clipboard', {
                title: 'Success',
                variant: 'success',
                solid: true,
                }
            );
        },
        copySecret(secret) {
            navigator.clipboard.writeText(secret);

            this.$root.$bvToast.toast('Secret copied to clipboard', {
                title: 'Success',
                variant: 'success',
                solid: true,
                }
            );
        }
    },
};
</script>
<style scoped>
::v-deep .action-column {
    min-width: 100px;
    vertical-align: middle !important;
}

.icons { 
    padding-top: 0rem !important;
}

.blur {
    filter: blur(3.5px);
}

.blur:hover {
    filter: blur(0);
}

::v-deep .key-column {
    width: 600px;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    ::v-deep .key-column {
        width: inherit;
        word-break: break-word;
    }
}
</style>